import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import Header from '../../components/layouts/Header'
import Footer from '../../components/layouts/Footer'
import ContactUs from '../../pages/ContactUs'
import Solution from '../../pages/Solution'
import AboutUs from '../../pages/AboutUs'
import Brands from '../../pages/Brands'
import Career from '../../pages/Career'
import Home from '../../pages/Home'
import Job from '../../pages/Job'

const AppRouter = () => {
  const [hideLogoWithName, setHideLogoWithName] = useState(false)

  return (
    <div>
      <Header hideLogoWithName={hideLogoWithName} />
      <Routes>
          <Route path="/" element={<Home hideLogoWithName={hideLogoWithName} setHideLogoWithName={setHideLogoWithName} />} />
          <Route path="/solution" element={<Solution />} />
          {/* <Route path="/brands" element={<Brands />} /> */}
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/career" element={<Career />} />
          {/* <Route path=":page/job/:jobId" element={<Job />} /> */}
      </Routes>
      <Footer />
    </div>
  )
}

export default AppRouter