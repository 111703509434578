import React from 'react'

import { useMediaQuery } from 'react-responsive';

import PositionContainer from '../../components/common/PositionContainer'
import { MainLayout } from '../../components/layouts/MainLayout/styled'
import FlexContainer from '../../components/common/FlexContainer'
import Typography from '../../components/common/Typography'
import HeaderText from '../../components/common/HeaderText'
import SolutionImg from "../../assets/gifs/Solution.jpg"
import Ellipse from '../../components/common/Ellipse'
import DivBox from '../../components/common/DivBox'
import Cards from '../../components/common/Cards'
import Icon from '../../components/common/Icon'
import Gif from '../../components/common/Gif'

import * as S from './styled'

const cardsData = [
  {
    cardId: 1,
    cardNumber: "01",
    cardText: "Find role which is your Rome Empire",
  },
  {
    cardId: 2,
    cardNumber: "02",
    cardText: "Check detail information about job",
  },
  {
    cardId: 3,
    cardNumber: "03",
    cardText: "Send us information about you with resume",
  },
  {
    cardId: 4,
    cardNumber: "04",
    cardText: "Wait for our reply. Good luck",
  },
]

const Solution = () => {

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isMobile ?
          <DivBox mobileOverflowX="hidden">
              <Ellipse
                topOnMobile={24}
                leftOnMobile={-20}
                widthOnMobile="200px"
                heightOnMobile="200px"
                background="rgba(221, 123, 16, 0.50)"
              />
              
              <Ellipse
                topOnMobile={40}
                leftOnMobile={40}
                widthOnMobile="250px"
                heightOnMobile="250px"
                borderRadius="500px"
                filterBlur="blur(125px)"
                background="rgba(15, 62, 82, 0.90)"
                translateXStart={100}
                translateXEnd={0}
              />

            <FlexContainer padding="24px 16px" justify="center" direction="column">
              <HeaderText fontSize="60px" fontWeight="700">Solution</HeaderText>
              <Typography
                width="343px"
                fontSize="16px"
                fontWeight={500}
                margin="32px 0px 40px 0px"
                lineHeight="30px"
              >
                {/* Efficient Logistics, Endless Solutions: Your Partner for Seamless Supply Chains. Bridging Distances, Enhancing Efficiency—We Deliver Beyond Expectations. */}
                At RANDT Corp, we offer more than just products – we provide solutions. Our platform is designed to cater to the diverse needs of our customers, offering a seamless shopping experience from start to finish. Whether you're searching for everyday essentials or specialty items, our curated selection and user-friendly interface make finding the perfect solution effortless.
              </Typography>
            </FlexContainer>
            <Gif
                  src={SolutionImg}
                  alt="solution1"
                  height="190px"
                  width="359px"
                  borderRadius="0px 200px 200px 0px"
            />
            
            <Icon filename="Rectangle" width="80%" margin="80px 0px 40px 0px" />

            <FlexContainer padding="24px 16px" justify="center" direction="column">
              <Typography
                textTransform="uppercase"
                fontSize="24px"
                fontWeight={700}
                color="rgba(255, 255, 255, 0.50)"
                padding="0px 0px 20px 0px"
              >
                Product Positioning
              </Typography>

              <Typography
                fontSize="16px"
                fontWeight={500}
                lineHeight="30px"
              >
              {/* We excel at creating partnerships with retailers from around the world. We leverage our understanding of both brands and retailers to get your products in front 
of consumers in less time. */}
                We pride ourselves on delivering top-notch products that are carefully positioned to meet the demands of our discerning customers. From cutting-edge technology to timeless classics, each item in our inventory is meticulously selected to ensure it aligns with our commitment to quality, innovation, and customer satisfaction. At RANDT Corp, you'll find products that not only meet your expectations but exceed them.
              </Typography>
            </FlexContainer>
            
            <FlexContainer justify="flex-end">
              <Icon filename="Vector" width="300px" height="212px" margin="8px 0px 24px 0px"/>
            </FlexContainer>

            <FlexContainer padding="24px 16px" justify="center" direction="column">
              <Gif
                src={SolutionImg}
                alt="solution"
                height="173px"
                width="343px"
                borderRadius="380px"
              />
              <Typography
                fontSize="24px"
                fontWeight={700}
                textTransform="uppercase"
                color="rgba(255, 255, 255, 0.50)"
                margin="40px 0px 20px 0px"
              >
                Supply Chain Management
              </Typography>

              <Typography
                width="343px"
                fontSize="16px"
                fontWeight={500}
                lineHeight="30px"
              >
                {/* With our next-level application, brands can list products, process orders, and create shipments all from one easy to use platform. */}
                Behind every successful transaction is a well-managed supply chain, and at RANDT Corp, we take supply chain management seriously. Through strategic partnerships and efficient logistics, we ensure that our products are sourced responsibly, stored securely, and delivered promptly to your doorstep. Our commitment to excellence extends throughout the entire supply chain, guaranteeing a smooth and reliable shopping experience for our customers.
              </Typography>

              <HeaderText
                textAlign="center"
                fontSize="50px"
                fontWeight={700}
                margin="80px 0px 40px 0px"
              >
                Additional Services
              </HeaderText>
              <Cards
                direction="column"
                cardsData={cardsData}
                padding="0px"
              />
            </FlexContainer>
          </DivBox>
        :
          <>
                <Ellipse
                  top={20}
                  left={0}
                  // translateXStart={0}
                  // translateXEnd={100}
                />
                
                <Ellipse
                  top={30}
                  left={50}
                  width="500px"
                  height="500px"
                  borderRadius="500px"
                  filterBlur="blur(125px)"
                  background="rgba(15, 62, 82, 0.90)"
                  translateXStart={100}
                  translateXEnd={0}
                />
              <FlexContainer margin="30px 0px 0px 0px">
                <Gif
                  src={SolutionImg}
                  alt="solution1"
                  height="100%"
                  width="40%"
                  borderRadius="0px 200px 200px 0px"
                  margin="0px 117px 0px 0px"
                />

                <FlexContainer direction="column" align="start" flexWrap="wrap" justify="center" width="60%">
                  <HeaderText>Solution</HeaderText>
                  <Typography fontSize="24px" fontWeight={500} lineHeight="36px" width="100%" maxWidth="829px">
                    {/* Efficient Logistics, Endless Solutions: Your Partner for Seamless Supply Chains. Bridging Distances, Enhancing Efficiency—We Deliver Beyond Expectations. */}
                    At RANDT Corp, we offer more than just products – we provide solutions. Our platform is designed to cater to the diverse needs of our customers, offering a seamless shopping experience from start to finish. Whether you're searching for everyday essentials or specialty items, our curated selection and user-friendly interface make finding the perfect solution effortless.
                  </Typography>
                </FlexContainer> 
              </FlexContainer>

              <FlexContainer margin="180px 0px 150px 0px">
                  <PositionContainer position="relative" width="100%">
                    <PositionContainer top="-150px" left="0">
                      <Icon filename="Rectangle" />
                    </PositionContainer>
                    <PositionContainer left="290px">
                      <Typography
                        textTransform="uppercase"
                        fontSize="36px"
                        fontWeight={700}
                        color="rgba(255, 255, 255, 0.50)"
                        padding="0px 0px 20px 0px"
                      >
                        Product Positioning
                      </Typography>
                      <Typography fontSize="20px" width="100%" maxWidth="850px">
                        {/* We excel at creating partnerships with retailers from around the world. We leverage our understanding of both brands and retailers to get your products in front of consumers in less time. */}
                        We pride ourselves on delivering top-notch products that are carefully positioned to meet the demands of our discerning customers. From cutting-edge technology to timeless classics, each item in our inventory is meticulously selected to ensure it aligns with our commitment to quality, innovation, and customer satisfaction. At RANDT Corp, you'll find products that not only meet your expectations but exceed them.
                      </Typography>
                    </PositionContainer>
                  </PositionContainer>
                  
                <DivBox>
                  <Icon filename="Vector"/>
                </DivBox>
              </FlexContainer>

              <MainLayout>
                <FlexContainer>
                  <Gif
                    src={SolutionImg}
                    alt="solution"
                    height="400"
                    width="756px"
                    borderRadius="380px"
                  />
                  
                  <DivBox margin="0px 0px 0px 115px">
                    <Typography
                      textTransform="uppercase"
                      fontSize="36px"
                      fontWeight={700}
                      color="rgba(255, 255, 255, 0.50)"
                      padding="0px 0px 20px 0px"
                    >
                      Supply Chain Management
                    </Typography>
                    <Typography fontSize="20px" width="100%" maxWidth="658px">
                      {/* With our next-level application, brands can list products, process orders, and create shipments all from one easy to use platform. */}
                      Behind every successful transaction is a well-managed supply chain, and at RANDT Corp, we take supply chain management seriously. Through strategic partnerships and efficient logistics, we ensure that our products are sourced responsibly, stored securely, and delivered promptly to your doorstep. Our commitment to excellence extends throughout the entire supply chain, guaranteeing a smooth and reliable shopping experience for our customers.
                    </Typography>
                  </DivBox>
                </FlexContainer>
                <FlexContainer direction="column" align="start">
                  <HeaderText fontSize="123px">Additional Servces</HeaderText>
                  <Cards cardsData={cardsData} padding="40px 0px" />
                </FlexContainer>
                <Ellipse
                  top={100}
                  left={0}
                  // translateXStart={0}
                  // translateXEnd={100}
                  background="rgba(221, 123, 16, 0.20)"
                />
                
                <Ellipse
                  top={100}
                  left={50}
                  width="500px"
                  height="500px"
                  borderRadius="500px"
                  filterBlur="blur(125px)"
                  background="rgba(15, 62, 82, 0.50)"
                  translateXStart={100}
                  translateXEnd={0}
              />
              </MainLayout>
            </>
      }
    </>
  )
}

export default Solution