import React from 'react'

import * as S from './styled'

const TextAnimation = ({
    text,
    hoverText,
    color,
    hoverTextColor,
    fontSize,
    fontWeight,
    containerWidth,
    containerHeight,
    background,
    bottom,
    left,
    ...rest
  }) => {
    return (
      <S.Container
        background={background}
        containerwidth={containerWidth}
        containerHeight={containerHeight}
        {...rest}
      >
        <S.Text
          color={color}
          fontsize={fontSize}
          fontweight={fontWeight}
          bottom={bottom}
          left={left}
        >
          {text}
        </S.Text>

        <S.HoverText
          color={hoverTextColor}
          fontsize={fontSize}
          fontweight={fontWeight}
          bottom={bottom}
          left={left}
        >
          {hoverText}
        </S.HoverText>
      </S.Container>
    );
  };
  
  export default TextAnimation;