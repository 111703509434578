import styled from 'styled-components'

export const Header = styled.header`
  position: sticky;
  top: 0rem;
  background: #0A151A;
  padding: .4rem;
  z-index: 1000000000;
`

export const LogoBox = styled.div`
  opacity: ${props => props.hideLogoWithName ? '1' : '0'};
`

export const HeaderNavigation = styled.nav`
  display:'flex';
  justify-content: space-around;
  align-items: center;
`

export const Ul = styled.ul`
  list-style: none;
`

export const MobileHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  width: 375px;
`