import React from 'react'

import * as S from './styled'

const ButtonRouterLink = ({
  backgroundColor,
  textColor,
  radius,
  padding,
  margin,
  height,
  width,
  to,
  activeClassName,
  children
}) => {
  return (
    <S.ButtonRouterLink
      background={backgroundColor}
      color={textColor}
      radius={radius}
      padding={padding}
      margin={margin}
      height={height}
      width={width}
      to={to}
      activeClassName={activeClassName}
    >
      {children}
    </S.ButtonRouterLink>
  )
}

export default ButtonRouterLink