import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import PositionContainer from '../PositionContainer';
import ButtonRouterLink from '../ButtonRouterLink';
import FlexContainer from '../FlexContainer';
import NavLinks from '../NavLinks';
import DivBox from '../DivBox';
import Icon from '../Icon';

import * as S from './styled'

const MobileModal = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {isMobile && (
        <>
          <FlexContainer>
            <ButtonRouterLink to="/">
              <Icon filename="Logo" width="37px" height="40px" />
            </ButtonRouterLink>
            <Icon filename="Menu" onClick={toggleModal} width="28px" height="28px" />
          </FlexContainer>
          {showModal && (
            <PositionContainer
                position="fixed"
                top={0}
                left={0}
                width="100vw"
                height="100vh"
                background="#0A151A"
                zIndex={1000000000}
            >
              <PositionContainer
                position="relative"
                padding="20px"
                background="#0A151A"
                minWidth="200px"
                maxWidth="100%"
              >
                <FlexContainer>
                <DivBox>
                    <Icon filename="Logo" width="37px" height="40px" />
                </DivBox>
                <DivBox>
                    <S.ButtonX
                        onClick={toggleModal}
                    >
                    <Icon filename="X" width="28px" height="28px" />
                    </S.ButtonX>
                </DivBox>
                </FlexContainer>
                <DivBox>
                  <NavLinks isMobile closeMobileModal={toggleModal} />
                    {/* {children} */}
                </DivBox>
              </PositionContainer>
            </PositionContainer>
          )}
        </>
      )}
    </>
  );
};

export default MobileModal;
