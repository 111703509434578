import React from 'react'

import * as S from './styled'

const PositionContainer = ({
    position,
    top,
    left,
    zIndex,
    minWidth,
    maxWidth,
    borderRadius,
    children,
    ...rest
  }) => {
  return (
    <S.PositionBox
        position={position}
        top={top}
        left={left}
        zIndex={zIndex}
        minwidth={minWidth}
        maxwidth={maxWidth}
        borderradius={borderRadius}
        {...rest}
    >
        {children}
    </S.PositionBox>
  )
}

export default PositionContainer