import React from 'react'

import * as S from './styled'

const Input = ({
    withStartIcon,
    withEndIcon,
    InputEndIconRightPosition,
    InputEndIconLeftPosition,
    InputEndIconTopPosition,
    fileName,
    type,
    onChange,
    value,
    height,
    width,
    border,
    padding,
    name,
    placeholder,
    ...rest
}) => {
  return (
    <S.InputContainer>

        {withStartIcon && <S.InputStartIcon filename={fileName} />}

        {
          type !== "file" 
            ?
              <S.Input
                  type={type}
                  onChange={onChange}
                  value={value}
                  height={height}
                  width={width}
                  border={border}
                  padding={padding}
                  name={name}
                  placeholder={placeholder}
                  autoComplete='off'
                  {...rest}
              />
            : <S.UploadInput
                  type={type}
                  onChange={onChange}
                  value={value}
                  height={height}
                  width={width}
                  border={border}
                  padding={padding}
                  name={name}
                  placeholder={placeholder}
                  autoComplete='off'
                  {...rest}
              />
        }


        {withEndIcon && 
          <S.InputEndIcon
            filename={fileName}
            InputEndIconRightPosition={InputEndIconRightPosition}
            InputEndIconLeftPosition={InputEndIconLeftPosition}
            InputEndIconTopPosition={InputEndIconTopPosition}
          />}
    </S.InputContainer>
  )
}

export default Input;
