import styled from 'styled-components'

export const Career = styled.div`
  width: 100%;
  position: relative;
`
export const NumberContainer = styled.div`
  text-align: center;
  margin-right: 50px;
`

export const NumberTexts = styled.p`
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.80);
  color: transparent;
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  font-family: sans-serif;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 767px) {
    font-size: ${props => props.mobileFontSize};
    font-weight: ${props => props.mobileFontWeight};
  }
  
  &::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35%; /* Cut 35% from the bottom */
  background: linear-gradient(to bottom, rgba(10, 21, 26, 1), #0a151a);
}
`