import React, { useEffect, useRef, useState } from 'react';

import { useMediaQuery } from 'react-responsive';

import FlexContainer from '../../components/common/FlexContainer';
import Typography from '../../components/common/Typography'
import Category from '../../components/common/Category'
import Section from '../../components/common/Section'
import Ellipse from '../../components/common/Ellipse'
import DivBox from '../../components/common/DivBox';
import Icon from '../../components/common/Icon'
import Gif from '../../components/common/Gif'

import Delivery from '../../assets/gifs/Delivery.jpg'

import * as S from './styled'

export const categoriesData = [
  {
    page: "home",
    role: "Marketing Assistance",
    roleTitle: "Marketing in the logistics industry can involve promoting services, building brand awareness, and establishing a strong online presence. Here are some general tips for marketing assistance in logistics",
    jobLink: "link",
    id: "1",
  },
  {
    role: "Transparency Systems",
    roleTitle: "Transparency systems in logistics play a crucial role in enhancing efficiency, trust, and accountability within the supply chain. Here are key components and strategies for implementing transparency systems in logistics",
    jobLink: "link",
    id: "2",
  },
  {
    role: "Reverse Logistics",
    roleTitle: "Reverse logistics refers to the process of moving goods from their final destination back to the manufacturer or a designated location for the purpose of returns, repairs, remanufacturing, recycling, or disposal. It involves the handling of products that have already been shipped to customers but need to be returned for various reasons.",
    jobLink: "link",
    id: "3",
  },
  {
    role: "Sales & Traffic Analysis",
    roleTitle: "Sales and traffic analysis in logistics involves examining data related to sales performance, customer behavior, and overall traffic within the logistics operations. This analysis is crucial for optimizing processes, improving efficiency, and making informed business decisions.",
    jobLink: "link",
    id: "4",
  },
  {
    role: "Information Technology",
    roleTitle: "Senior Systems engineer, Content Management",
    jobLink: "link",
    id: "5",
  },
  {
    role: "Marketing",
    roleTitle: "Senior Marketing Lead(Head of Marketing)",
    jobLink: "link",
    id: "6",
  },
]

// const mobileCategoriesData = [
//   {
//     page: 'home',
//     role: "Secure Sales Avenue",
//     roleTitle: "This could involve secure online sales channels, software solutions, or platforms tailored to the logistics sector. Such systems may focus on ensuring the confidentiality, integrity, and availability of sales and transactional data within the logistics supply chain.",
//     jobLink: "link",
//     id: "1",
//   },
//   {
//     role: "Marketing Assistance",
//     roleTitle: "Marketing in the logistics industry can involve promoting services, building brand awareness, and establishing a strong online presence. Here are some general tips for marketing assistance in logistics",
//     jobLink: "link",
//     id: "2",
//   },
//   {
//     role: "Transparency Systems",
//     roleTitle: "Transparency systems in logistics play a crucial role in enhancing efficiency, trust, and accountability within the supply chain. Here are key components and strategies for implementing transparency systems in logistics",
//     jobLink: "link",
//     id: "3",
//   },
//   {
//     role: "Reverse Logistics",
//     roleTitle: "Reverse logistics refers to the process of moving goods from their final destination back to the manufacturer or a designated location for the purpose of returns, repairs, remanufacturing, recycling, or disposal. It involves the handling of products that have already been shipped to customers but need to be returned for various reasons.",
//     jobLink: "link",
//     id: "4",
//   },
//   {
//     role: "Sales & Traffic Analysis",
//     roleTitle: "Sales and traffic analysis in logistics involves examining data related to sales performance, customer behavior, and overall traffic within the logistics operations. This analysis is crucial for optimizing processes, improving efficiency, and making informed business decisions.",
//     jobLink: "link",
//     id: "5",
//   },
//   {
//     role: "Secure Sales Avenue",
//     roleTitle: "This could involve secure online sales channels, software solutions, or platforms tailored to the logistics sector. Such systems may focus on ensuring the confidentiality, integrity, and availability of sales and transactional data within the logistics supply chain.",
//     jobLink: "link",
//     id: "6",
//   },
//   {
//     role: "Marketing Assistance",
//     roleTitle: "Marketing in the logistics industry can involve promoting services, building brand awareness, and establishing a strong online presence. Here are some general tips for marketing assistance in logistics",
//     jobLink: "link",
//     id: "7",
//   },
//   {
//     role: "Transparency Systems",
//     roleTitle: "Transparency systems in logistics play a crucial role in enhancing efficiency, trust, and accountability within the supply chain. Here are key components and strategies for implementing transparency systems in logistics",
//     jobLink: "link",
//     id: "8",
//   },
//   {
//     role: "Reverse Logistics",
//     roleTitle: "Reverse logistics refers to the process of moving goods from their final destination back to the manufacturer or a designated location for the purpose of returns, repairs, remanufacturing, recycling, or disposal. It involves the handling of products that have already been shipped to customers but need to be returned for various reasons.",
//     jobLink: "link",
//     id: "9",
//   },
//   {
//     role: "Sales & Traffic Analysis",
//     roleTitle: "Sales and traffic analysis in logistics involves examining data related to sales performance, customer behavior, and overall traffic within the logistics operations. This analysis is crucial for optimizing processes, improving efficiency, and making informed business decisions.",
//     jobLink: "link",
//     id: "10",
//   },
// ]

const Home = ({hideLogoWithName, setHideLogoWithName}) => {

  const logoWithNameRef = useRef(null);
  const [gifWidthRef, setgifWidthRef]  = useState('73%')
  const [moveLetters, setMoveLetters] = useState(false)
  // const [hideLogoWithName, setHideLogoWithName] = useState(false)

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if(!isMobile) {
        if (scrollPosition > 50) {
          setgifWidthRef('85%')
          setHideLogoWithName(true)
        } else {
          setgifWidthRef('60%')
          setHideLogoWithName(false)
        }
      }

      if(!isMobile){
        if(scrollPosition > 800) {
          setMoveLetters(true)
        } else {
          setMoveLetters(false)
        }
      }

      if(isMobile) {
        if(scrollPosition > 300) {
         setMoveLetters(true)
        } else {
         setMoveLetters(false)
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);
  
  return (
    <>
      {isMobile ?
      <>
          <Ellipse
            topOnMobile={10}
            leftOnMobile={0}
            widthOnMobile="200px"
            heightOnMobile="200px"
            background="rgba(221, 123, 16, 0.50)"
            backgroundOnMobile="rgba(221, 123, 16, 0.80)"
            // translateXStart={0}
            // translateXEnd={100}
          />

        <Ellipse
            topOnMobile={30}
            leftOnMobile={5}
            widthOnMobile="250px"
            heightOnMobile="250px"
            borderRadius="500px"
            filterBlur="blur(125px)"
            backgroundOnMobile="rgba(15, 62, 82, 0.90)"
            translateXStart={100}
            translateXEnd={0}
        />
            
          <Ellipse
            topOnMobile={70}
            leftOnMobile={10}
            width="250px"
            height="250px"
            borderRadius="500px"
            filterBlur="blur(125px)"
            background="rgba(15, 62, 82, 0.90)"
            translateXStart={100}
            translateXEnd={0}
        />
        <S.MobileHomeContainer>
          <Icon filename="RandtCorpTextMobile" width="226px" margin="48px 0px 12px 0px" />
          <Typography mobileFontSize="18px" mobileTextAlign="center" mobileFontWeight={400}>GLOBAL DISTRIBUTION</Typography>
          <Typography mobileFontSize="18px" mobileTextAlign="center" mobileFontWeight={400}>OF CONSUMER BRANDS</Typography>
        </S.MobileHomeContainer>
        <Gif src={Delivery} width="100%" altName="postDelivery" margin="40px 0px 100px 0px" />

        <FlexContainer
          padding="70px 0px 0px 0px"
          overflowX="hidden"
          position="relative"
          justify="center"
          width="100vw"
          margin="0px 0px 50px 0px"
        >
          <S.HomeAnimationContainer isMobile>
            <S.AnimatedImagesBox>
              <S.LeftImage
                filename="TLetter"
                moveAnimation={moveLetters}
                width={"170px"}
                height={"170px"}
                isMobile
              />
              <S.RightImage
                filename="RR"
                moveAnimation={moveLetters}
                width={"235px"}
                height={"183px"}
                isMobile
              />
            </S.AnimatedImagesBox>
          </S.HomeAnimationContainer>
        </FlexContainer>

        <Icon filename="PartnersMobile" />
        <Icon filename="BrandServices" margin="80px 0px 24px 0px" />

        <S.MobileHomeContainer>
          <Category categoriesData={categoriesData} />
        </S.MobileHomeContainer>
      </>
      :
        <S.Home>
            <Ellipse
              top={5}
              left={0}
              // translateXStart={0}
              // translateXEnd={100}
            />
            
            <Ellipse
              top={5}
              left={50}
              width="500px"
              height="500px"
              borderRadius="500px"
              filterBlur="blur(125px)"
              background="rgba(15, 62, 82, 0.90)"
              translateXStart={100}
              translateXEnd={0}
          />


          {/* <Ellipse /> */}
          <FlexContainer justify="center" height={`${hideLogoWithName && "100%"}`}>
            <S.LogoWithName
              ref={logoWithNameRef}
              hideLogoWithName={hideLogoWithName}
            >

              <FlexContainer align="end" justify="center" margin="0px 0px 20px 0px">
                <Icon filename="RLogo" width="110px" height="117px" />
                <DivBox padding="0px 0px 2px 0px" margin="0px 0px 0px 20px">
                  <Icon filename="RandtCorp" width="436px" height="50px"/>
                </DivBox>
              </FlexContainer>
              
              {/* <div style={{display:'flex', alignItems:'end', justifyContent:'center', position:'relative', }}>
              <Icon filename="Logo" width="100px" top={hideLogoWithName && '-40px'} />
              <Typography textTransform="uppercase" position={hideLogoWithName && 'absolute'} top='-100px' fontSize={!hideLogoWithName && '48px'} fontWeight="bold">Randt Corp</Typography>
              </div> */}

              {/* <Icon filename="LogoWithName" /> */}
              <Typography>Global Distribution of Consumer Brands</Typography>
            </S.LogoWithName>
          </FlexContainer>

          <S.TextAlignBox>
            <Gif src={Delivery} width={gifWidthRef} altName="postDelivery" padding={`${hideLogoWithName ? "0px 80px 100px 80px" : "100px"}`} />
          </S.TextAlignBox>

            <Section display="flex" padding="0px 430px 0px 220px">
              <S.Box>
                <S.MoveTypography moveAnimation={moveLetters}>We create a haven for brands and</S.MoveTypography>
                <S.MoveTypography moveAnimation={moveLetters}>give them the oportunity to </S.MoveTypography>
                <S.MoveTypography moveAnimation={moveLetters}>expand beyond their reach.</S.MoveTypography>
              </S.Box>
                
              <S.HomeAnimationContainer hideLogoWithName={hideLogoWithName}>
                <S.AnimatedImagesBox>
                  <S.LeftImage filename="T" moveAnimation={moveLetters} />
                  <S.RightImage filename="R" moveAnimation={moveLetters} />
                </S.AnimatedImagesBox>
              </S.HomeAnimationContainer>
            </Section>

            {/* <S.TextAlignBox>
              <Icon filename="Partners2" />
            </S.TextAlignBox> */}

            {/* <Section>
              <Category categoriesData={categoriesData} />
            </Section> */}
        </S.Home>
      }
    </>
  )
}

export default Home