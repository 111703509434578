import styled from 'styled-components';


export const Modal = styled.div`
  display: ${props => props.display};
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  padding: ${props => props.padding || '80px'};
  margin: ${props => props.margin};
  background: rgba(10, 21, 26, 0.50);
  width: ${props => props.width};
  /* width: 1142px; */

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
  }
`;