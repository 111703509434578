import React from 'react'

import FlexContainer from '../FlexContainer'
import Card from './Card'

// import * as S from './styled'

const Cards = ({
  cardsData,
  padding,
  direction,
  mobileFlexDirection,
  mobileJustify,
  mobilePadding,
  ...rest
}) => {
  return (
    <FlexContainer
      direction={direction}
      padding={padding || "0px 195px"}
      mobilePadding={mobilePadding}
      mobileFlexDirection={mobileFlexDirection}
      mobileJustify={mobileJustify}
    >
      {cardsData?.map(cardData => (
              <Card
                  cardId={cardData.id}
                  cardNumber={cardData.cardNumber}
                  cardText={cardData.cardText}
                  {...rest}
              />
          )
      )}
    </FlexContainer>
  )
}

export default Cards