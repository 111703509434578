import React from 'react'

import * as S from './styled'

const DivBox = ({children, ...rest}) => {
  return (
    <S.DivBox
        {...rest}
    >
        {children}
    </S.DivBox>
  )
}

export default DivBox