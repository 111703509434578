import styled from 'styled-components'

export const FlexContainer = styled.div`
   display: flex;
   flex-direction: ${props => props.direction};
   flex-wrap: ${props => props.flexWrap};
   justify-content: ${(props) => props.justify || "space-between"};
   align-items: ${(props) => props.align || "center"};
   width: ${(props) => props.width};
   max-width: ${props => props.maxWidth};
   height: ${(props) => props.height};
   margin: ${props => props.margin};
   padding: ${props => props.padding};
   border: ${props => props.border};
   border-bottom: ${props => props.borderBottom};
   overflow-x: ${props => props.overflowX};
   overflow-y: ${props => props.overflowY};
   background: ${props => props.background};
   position: ${props => props.position};
   z-index: ${props => props.zIndex};
   gap: ${props => props.gap};

   @media (max-width: 767px) {
      padding: ${props => props.mobilePadding};
      margin: ${props => props.mobileMargin};
      border-top: ${props => props.mobileBorderTop};
      flex-direction: ${props => props.mobileFlexDirection};
      justify-content: ${(props) => props.mobileJustify};
      position: ${props => props.mobilePosition};
      z-index: ${props => props.mobileZindex};
      overflow-x: ${props => props.mobileOverflowX};
      overflow-y: ${props => props.mobileOverflowY};
      gap: ${props => props.mobileGap};
    }
`
