import styled from "styled-components"

export const NavLinkUl = styled.ul`
    display: flex;
    align-items: ${props => props.isMobile ? "center" : "start"};
    justify-content: ${props => props.isMobile ? "center" : "space-between"};
    list-style: none;
    padding: 0;
    flex-direction: ${props => props.isMobile && "column"};
`

export const MobileList = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`