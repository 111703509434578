import styled from "styled-components"

export const FooterContainer = styled.div`
    background: rgba(15, 62, 82, 0.10);
    padding-top: 20px;
    color: #ffff;

    @media (max-width: 767px) {
        display: flex;
        align-items: center;
        justify-content: center;
  }
`

export const ContactInformationBox = styled.div`
    text-align: end;
`

export const MobileFooterContainer = styled.div`
    width: 375px;
`