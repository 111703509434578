import React from 'react'

import * as S from './styled'

const TextArea = ({name, id, rows, cols, placeholder, ...rest}) => {
  return (
    <S.TextArea
        name={name}
        id={id}
        rows={rows || 4}
        cols={cols}
        placeholder={placeholder}
        {...rest}
    >
    </S.TextArea>
  )
}

export default TextArea