import Typography from "../../Typography";

import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    margin:${props => props.margin || "0px 20px 0px 0px"};
    width: 100%;
    height: 240px;
    padding: 40px 32px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 40px;
    background: linear-gradient(130deg, rgba(255, 255, 255, 0.10) 27.02%, rgba(255, 255, 255, 0.00) 100%);
    
    @media (max-width: 767px) {
        margin: 0px 0px 20px 0px;
        padding: 28px 24px 24px 24px;
        height: 158px;
        width: 343px;
        justify-content: center;
    }
`

export const CardNumber = styled(Typography)`
    color: #0A151A;
    font-size: 60px;
    font-weight: 900;
`