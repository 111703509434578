import React from 'react'

import { useMediaQuery } from 'react-responsive';

import FlexContainer from '../../common/FlexContainer/FlexContainer'
import { MainLayout } from '../MainLayout/styled'
import Typography from '../../common/Typography'
import NavLinks from '../../common/NavLinks'
import Icon from '../../common/Icon'

import * as S from './styled'

const Footer = () => {

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
        {isMobile ?
        <S.FooterContainer>      
            <MainLayout width="375px" padding="0">
                <FlexContainer justifyContent="center" align="center" direction="column">
                    <Typography fontSize="14px" fontWeight={400} width="226px" margin="24px 0px 28px 0px">
                        GLOBAL DISTRIBUTION OF CONSUMER BRANDS
                    </Typography>

                    <NavLinks fontSize="14px" fontWeight={500} isCareer={true} />

                    <Typography
                        textTransform="uppercase"
                        fontSize="14px"
                        fontWeight="500"
                        margin="48px 0px 0px 0px"
                    >
                        Contact us
                    </Typography>

                    <FlexContainer margin="20px 0px">
                        <Typography
                            fontSize="16px"
                            fontWeight="300"
                            textDecoration="underline"
                            margin="0px 16px 0px 0px"
                        >
                            888-257-3478
                        </Typography>
                        <Typography
                            fontSize="16px"
                            fontWeight="300"
                            textDecoration="underline"
                        >
                            {/* info@CLSBrands.com */}
                            info@randtcorp.com
                        </Typography>
                    </FlexContainer>
                </FlexContainer>
                 <Icon filename="RandtCorpMobile" width="375px" />
            </MainLayout>
        </S.FooterContainer>
        :
            <S.FooterContainer>
                    <MainLayout>
                        <div style={{display: "flex", alignItems:"end", justifyContent:'space-around', paddingBottom:'80px'}}>
                            <FlexContainer justify="flex-start" align="start" direction="column" >
                                        <div>
                                            <Icon filename="Logo1" width="30px"/>
                                        </div>
                                            <Typography
                                                fontSize="14px"
                                                fontWeight="400"
                                                textTransform="uppercase"
                                                textAlign="start"
                                            >
                                                Global distribution of
                                            </Typography>
                                            <Typography
                                                fontSize="14px"
                                                fontWeight="400"
                                                textTransform="uppercase"
                                                textAlign="start"
                                            >
                                                consumer brands
                                            </Typography>
                            </FlexContainer>

                            <NavLinks isCareer={true} />

                            <S.ContactInformationBox>
                                <Typography
                                    textTransform="uppercase"
                                    fontSize="16px"
                                    fontWeight="500"
                                >
                                    Contact us
                                </Typography>
                                <FlexContainer>
                                    
                                    <Typography
                                        fontSize="16px"
                                        fontWeight="300"
                                        textDecoration="underline"
                                        margin="0px 16px 0px 0px"
                                    >
                                        {/* 888-257-3478 */}
                                        +1 (929) 382-8394
                                    </Typography>
                                    <Typography
                                        fontSize="16px"
                                        fontWeight="300"
                                        textDecoration="underline"
                                        margin="0px 16px 0px 0px"
                                    >
                                        +1 (347) 954-6448
                                    </Typography>

                                    <Typography
                                        fontSize="16px"
                                        fontWeight="300"
                                        textDecoration="underline"
                                    >
                                        {/* info@CLSBrands.com */}
                                        info@randtcorp.com
                                    </Typography>
                                </FlexContainer>
                            </S.ContactInformationBox>
                        </div>
                    </MainLayout>

                    <FlexContainer justify="center">
                        <Icon filename="RANDTCORP" />
                    </FlexContainer>
            </S.FooterContainer>
        }
    </>
  )
}

export default Footer