import DivBox from '../../components/common/DivBox'
import FlexContainer from '../../components/common/FlexContainer'

// import ReactFlagsSelect from "react-flags-select"

import styled from 'styled-components'

// export const ReactFlags = styled(ReactFlagsSelect)`
//     background-color: transparent;
//     max-width: 50px;
// `

export const ContactUs = styled.div`
    background: rgba(10, 21, 26, 0.50);
    padding: 100px 0px 0px 198px;
`
export const SubmitButtonBox = styled.div`
    text-align: center;
    margin: 20px 0px;
`
export const SubmitForm = styled.form`
    margin-top: 60px;

    @media (max-width: 767px) {
        margin-top: 32px;
    }
`
export const FlexBox = styled(FlexContainer)`
    padding: 0px 0px 0px 198px;
    
    @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 16px;
        overflow: hidden;
    }
`

export const ContactInfoBox = styled(DivBox)`
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding: 0px 16px;
        overflow: hidden;
        min-width: 343px;
        width: 100%;
    }
`

export const Box = styled(DivBox)`
    @media (max-width: 767px) {
        min-width: 300px;
        width: 100%;
    }
`

export const DivContainer = styled.div`
    @media (max-width: 767px) {
        overflow: hidden;
    }
`