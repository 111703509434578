import styled, { keyframes } from 'styled-components';

import { FlexContainer } from '../FlexContainer/styled';

const slideRight = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(15px);
  }
`;


export const CategoriesContainer = styled.div`
    padding: 100px 150px;
`;

export const CategoriesBox = styled.div`
  width: ${props => props.width || '40%'};
`

export const ArrowBox = styled.div`
    cursor: pointer;
    
    ${FlexContainer}:hover &{
    animation: ${slideRight} 0.5s forwards;
  }
`

export const RoleTitle = styled.h3`
    color: rgba(255, 255, 255, 0.80);
`

export const RolePlace = styled.p`
    color: rgba(255, 255, 255, 0.80);

    @media (max-width: 767px) {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
    }
`

export const LineBox = styled.div`
    padding: 44px 0px;
`