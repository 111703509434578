import styled from 'styled-components'


export const Button = styled.button`
  border: none;
  background: ${(props) => props.background || "#0F3E52"};
  color: ${(props) => props.color};
  border-radius: ${(props) => props.radius || "30px"};
  padding: ${(props) => props.padding || '10px'};
  margin: ${(props) => props.margin};
  height: ${(props) => props.height && props.height};
  width: ${(props) => props.width && props.width};
  text-decoration: none;
  outline: none;
  cursor: pointer;

  &.active {
    color: #DD7B10;
  }
`