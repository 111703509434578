import React from 'react'

import * as S from './styled'

const Modal = ({display, justify, align, children, ...rest}) => {
  return (
    <S.Modal display={display} justify={justify} align={align} {...rest}>{children}</S.Modal>
  )
}

export default Modal