import styled from 'styled-components';

export const Section = styled.section`
    display:${props => props.display};
    flex-direction: ${props => props.flexDirection};
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    text-align: ${props => props.textAlign};
    height: ${props => props.height};
    width: ${props => props.width};
    background: ${props => props.background};
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    max-width: ${props => props.maxWidth};

    @media (max-width: 767px) {
        overflow-x: hidden;
        margin: ${props => props.mobileMargin};
        padding: ${props => props.mobilePadding};
        height: ${props => props.mobileHeight};
    }
`