import styled from 'styled-components';

export const PositionBox = styled.div`
    position: ${props => props.position || "absolute"};
    top: ${props => props.top};
    bottom: ${props => props.bottom};
    left: ${props => props.left};
    right: ${props => props.right};
    z-index: ${props => props.zIndex};
    height: ${props => props.height};
    width: ${props => props.width};
    min-width: ${props => props.minwidth};
    max-width: ${props => props.maxwidth};
    border: ${props => props.border};
    background: ${props => props.background};
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    display: ${props => props.display};
    border-radius: ${props => props.borderradius};

    @media (max-width: 767px) {
        position: ${props => props.mobilePosition};
        top: ${props => props.mobileTop};
        bottom: ${props => props.mobileBottom};
        left: ${props => props.mobileLeft};
        right: ${props => props.mobileRight};
        display: ${props => props.mobileDisplay};
        padding: ${props => props.mobilePadding};
        margin: ${props => props.mobileMargin};
        height: ${props => props.mobileHeight};
        width: ${props => props.mobileWidth};
        z-index: ${props => props.mobileZindex};
    }
`