import React from 'react'

import * as S from './styled'

const Gif = ({src, altName, height, width, ...rest}) => {
  return (
    <S.Gif src={src} alt={altName} height={height} width={width} {...rest} />
  )
}

export default Gif