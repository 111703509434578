import Icon from '../../components/common/Icon';

import styled from 'styled-components'
import Typography from '../../components/common/Typography';

export const Home = styled.div`
  position: relative;
`

export const LogoWithName = styled.div`
  width: ${props => props.hideLogoWithName ? '0' : '100%'};
  height: ${props => props.hideLogoWithName ? '20px' : '100%'};
  position: ${props => props.hideLogoWithName && "absolute"};
  text-align: center;
  padding: ${props => props.hideLogoWithName ? "0px" : "60px 20px 20px 20px"};
  opacity: ${props => props.hideLogoWithName && "0"};
  transition-duration: 1s;
  transform: translateY(${props => props.hideLogoWithName && "-100%"});
`

export const MoveTypography = styled(Typography)`
  font-size: 34px;
  font-weight: 400;
  opacity: ${props => props.moveAnimation ? '1' : '0'};
  transform: translateY(${ props => props.moveAnimation && "-100%"});
  transition: transform .5s;
`

export const HomeAnimationContainer = styled.div`
  position: ${props => !props.isMobile && "relative"};
  height: ${props => props.isMobile? "145px" : "270px"};
  width: ${props => props.isMobile? "270px" : "580px"};
  background-image: url('/svg/VectorsBackground.svg');
  background-size: cover;
  background-position: center;
  margin-bottom: 200px;
`

export const AnimatedImagesBox = styled.div`
  position: relative;
  text-align: center;
  color: transparent;
  -webkit-text-stroke: 2px black;
  margin-top: 0;
`;

export const LeftImage = styled(Icon)`
position: absolute;
top: ${props => props.isMobile ? "-13px" : "0px"};
left: ${props => props.isMobile ? "-30px" : "-100px"};
transform: translateX(${ props => props.moveAnimation && "-30%"});
transition: transform .5s;
height: ${props => !props.isMobile && "270px"};
`;

export const RightImage = styled(Icon)`
position: absolute;
top: ${props => props.isMobile ? "-36px" : "-104px"};
left: ${props => props.isMobile ? "70px" : "100px"};
transform: translateX(${ props => props.moveAnimation && "25%"});
transition: transform .5s;
`;

export const TextAlignBox = styled.div`
  text-align: center;
`

export const Box = styled.div`
  width: 100%;
  padding-top: 150px;
`

export const MobileHomeContainer = styled.div`
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`