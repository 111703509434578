import React from 'react'

import ButtonRouterLink from '../ButtonRouterLink'
import TextAnimation from '../TextAnimation'
import Icon from '../Icon'

import * as S from './styled'

const NavLinks = ({ isCareer, isMobile, fontSize, fontWeight, closeMobileModal }) => {
  return <>
    {isMobile ?
        <div>
            <S.NavLinkUl isMobile>
                <S.MobileList onClick={closeMobileModal}>
                    <ButtonRouterLink
                            to="/"
                            textColor="#ffff"
                            activeClassName="active"
                        >   
                            HOME
                            {/* <TextAnimation containerWidth="50px" text="HOME" hoverText="HOME" /> */}
                    </ButtonRouterLink>
                    <Icon filename="BlueLine" margin="32px 0px" />
                </S.MobileList>

                <S.MobileList onClick={closeMobileModal}>
                    <ButtonRouterLink
                            to="/solution"
                            textColor="#ffff"
                            activeClassName="active"
                        >   
                            SOLUTION
                            {/* <TextAnimation text="SOLUTION" hoverText="SOLUTION" /> */}
                    </ButtonRouterLink>
                    <Icon filename="BlueLine" margin="32px 0px" />
                </S.MobileList>

                {/* <S.MobileList onClick={closeMobileModal}> */}
                    {/* <ButtonRouterLink
                            to="/brands"
                            textColor="#ffff"
                            activeClassName="active"
                        >    */}
                            {/* BRANDS */}
                            {/* <TextAnimation text="BRANDS" hoverText="BRANDS" /> */}
                    {/* </ButtonRouterLink> */}
                    {/* <Icon filename="BlueLine" margin="32px 0px" /> */}
                {/* </S.MobileList> */}

                <S.MobileList onClick={closeMobileModal}>
                    <ButtonRouterLink
                            to="/about"
                            textColor="#ffff"
                            activeClassName="active"
                        >   
                            ABOUT US
                            {/* <TextAnimation text="ABOUT US" hoverText="ABOUT US" /> */}
                    </ButtonRouterLink>
                    <Icon filename="BlueLine" margin="32px 0px" />
                </S.MobileList>

                <S.MobileList onClick={closeMobileModal}>
                    <ButtonRouterLink
                            to="/contact"
                            textColor="#ffff"
                            activeClassName="active"
                        >
                            CONTACT US
                            {/* <TextAnimation containerWidth="110px" text="CONTACT US" hoverText="CONTACT US" /> */}
                    </ButtonRouterLink>
                </S.MobileList>
            </S.NavLinkUl>
        </div>
    :
        <S.NavLinkUl>
            <li>
                <ButtonRouterLink
                    to="/solution"
                    textColor="#ffff"
                    activeClassName="active"
                >   
                    <TextAnimation
                        fontSize={fontSize}
                        fontWeight={fontWeight}
                        containerWidth="100px"
                        text="SOLUTION"
                        hoverText="SOLUTION"
                    />
                </ButtonRouterLink>
            </li>
            {/* <li>
                <ButtonRouterLink
                    to="/brands"
                    textColor="#ffff"
                    activeClassName="active"
                >
                    <TextAnimation
                        fontSize={fontSize}
                        fontWeight={fontWeight}
                        containerWidth="85px"
                        text="BRANDS"
                        hoverText="BRANDS"
                    />
                </ButtonRouterLink>
            </li> */}
            <li>
                <ButtonRouterLink
                    to="/about"
                    textColor="#ffff"
                    activeClassName="active"
                >   
                    <TextAnimation
                        fontSize={fontSize}
                        fontWeight={fontWeight}
                        containerWidth="100px"
                        text="ABOUT US"
                        hoverText="ABOUT US"
                    />
                </ButtonRouterLink>
            </li>

            {
                isCareer && <li>
                    <ButtonRouterLink
                        to="/career"
                        textColor="#ffff"
                        activeClassName="active"
                    >
                        <TextAnimation
                            fontSize={fontSize}
                            fontWeight={fontWeight}
                            text="CAREER"
                            hoverText="CAREER"
                        />
                    </ButtonRouterLink>
                </li>
            }
        </S.NavLinkUl>
    }
  </>
}

export default NavLinks