import { NavLink } from 'react-router-dom';

import styled from 'styled-components'


export const ButtonRouterLink = styled(NavLink)`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border-radius: ${(props) => props.radius};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  text-decoration: none;

  &.active {
    color: #DD7B10;
  }

  &.active {
    > div > p {
      color:  #DD7B10;
    }
  }
`