import styled from 'styled-components'

import FlexContainer from '../../components/common/FlexContainer'
import Typography from '../../components/common/Typography'
import HeaderText from '../../components/common/HeaderText'
import Section from '../../components/common/Section'
import Ellipse from '../../components/common/Ellipse'
import Input from '../../components/common/Input'
import Label from '../../components/common/Label'

export const UploadInput = styled(Input)`
    display: none;
`

export const UploadLabel = styled(Label)`
    display: inline-block;
    cursor: pointer;
    padding: 10px 0px;
`

export const UploadInputBox = styled.div`
    width: 100%;
    border-bottom:2px solid rgba(255, 255, 255, 0.10);
`

export const ApplyButtonBox = styled.div`
    text-align: center;
`

export const ApplyForm = styled.form`
    margin-top: 40px;
`

export const FlexBox = styled(FlexContainer)`
    @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 16px;
        overflow: hidden;
    }
`

export const MainHeaderText = styled(HeaderText)`
    font-size: 100px;
    padding-bottom: 40px;

    @media (max-width: 767px) {
        font-size: 40px;
        font-weight: 700;
        width: 336px;
        padding-bottom: 20px;
    }
`

export const SectionBox = styled(Section)`
    @media (max-width: 767px) {
        max-width: 343px;
        width: 100%;
    }
`
export const TypographyText = styled(Typography)`
        font-size: 28px;
        color: rgba(255, 255, 255, 0.80);

        @media (max-width: 767px) {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        /* width: 343px; */
    }
`

export const ApplyNowText = styled(Typography)`
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    color: rgba(255, 255, 255, 0.80);

    @media (max-width: 767px) {
        font-size: 24px;
        margin: 20px 0px 32px 0px;
    }
`

export const OrangeEllipse = styled(Ellipse)`
    top: 20;
    left: 0;
    background: rgba(221, 123, 16, 0.30);
`

export const BlueEllipse = styled(Ellipse)`
    top: 30;
    left: 50;
    width: 500px;
    height: 500px;
    border-radius: 500px;
    background: rgba(15, 62, 82, 0.80);
`