import styled, { keyframes } from 'styled-components';

const runTextAnimation = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(130%);
  }
`;

export const AboutUs = styled.div`
  margin: 0px 0px 168px 0px;
`
export const RandtCorpRunningText = styled.div`
  padding-top: 800px;
  animation: ${runTextAnimation} 5s linear infinite;
  white-space: nowrap;
  transform-origin: top left;
  width: 100%;
  max-width: 195px;
`