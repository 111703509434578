import styled from "styled-components";

export const Label = styled.label`
    font-size: ${props => props.fontsize || "14px"};
    font-weight: ${props => props.fontweight || 300};
    line-height: ${props => props.lineheight || 'normal'};
    color: ${props => props.color || "#FFF"};
    text-transform: ${props => props.texttransform};
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    width: ${props => props.width};
    max-width: ${props => props.maxwidth};
`;