import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import AppRouter from './routes/appRouter';

import './App.css';

function App() {
  return (
    <div className="App">
        {/* <Header /> */}
        <AppRouter />
        {/* <Footer /> */}
      </div>
  );
}

export default App;
