// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400; /* Regular */
  src: url('https://fonts.gstatic.com/s/lexend/v2/pxiKyp0ihIEF2jsDegD-v8Ozf_fT.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 700; /* Bold */
  src: url('https://fonts.gstatic.com/s/lexend/v2/pxiDyp0ihIEF2jsDegD-v8Pzl_Rxmw.ttf') format('truetype');
}

* {
  padding: 0px;
  margin: 0px;
  color: #ffff;
  box-sizing: border-box;
  font-family: 'Lexend', sans-serif;
}

body {
  background-color: #0A151A;
  font-family: 'Lexend', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB,EAAE,YAAY;EAC9B,qGAAqG;AACvG;;AAEA;EACE,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB,EAAE,SAAS;EAC3B,uGAAuG;AACzG;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,iCAAiC;AACnC","sourcesContent":["@font-face {\n  font-family: 'Lexend', sans-serif;\n  font-style: normal;\n  font-weight: 400; /* Regular */\n  src: url('https://fonts.gstatic.com/s/lexend/v2/pxiKyp0ihIEF2jsDegD-v8Ozf_fT.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Lexend', sans-serif;\n  font-style: normal;\n  font-weight: 700; /* Bold */\n  src: url('https://fonts.gstatic.com/s/lexend/v2/pxiDyp0ihIEF2jsDegD-v8Pzl_Rxmw.ttf') format('truetype');\n}\n\n* {\n  padding: 0px;\n  margin: 0px;\n  color: #ffff;\n  box-sizing: border-box;\n  font-family: 'Lexend', sans-serif;\n}\n\nbody {\n  background-color: #0A151A;\n  font-family: 'Lexend', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
