import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import ButtonRouterLink from '../../common/ButtonRouterLink'
import TextAnimation from '../../common/TextAnimation';
import MobileModal from '../../common/MobileModal';
import NavLinks from '../../common/NavLinks';
import Button from '../../common/Button';
import Icon from '../../common/Icon';

import * as S from './styled'


const Header = ({hideLogoWithName}) => {
    
    const [logoAppear, setLogoAppear] = useState(false)
    const [scrollLogoAppear, setScrollLogoAppear] = useState(false)

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const { pathname }  = useLocation()

    useEffect(() => {
        pathname === ("/" || "") ? setLogoAppear(false) : setLogoAppear(true)
    }, [pathname])

    useEffect(() => {
        const handleScroll = () => {
          const isTop = window.scrollY < 100;
          setScrollLogoAppear(!isTop);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
    
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

  return <>
    {
         isMobile ?
        <S.Header>
            <MobileModal />
        </S.Header>
        
        :
            <S.Header>
                <nav style={{display:"flex", justifyContent:'space-around', alignItems:'center'}}> 
                    
                    <NavLinks />
                    
                    {
                        logoAppear && <ButtonRouterLink to="/" textColor="#ffff">
                                        <Icon filename="Logo" width="56px" height="60px" />
                                    </ButtonRouterLink>
                    }

                    {
                        pathname === ("/" || "") && 
                            <S.LogoBox hideLogoWithName={hideLogoWithName}>
                                <Button backgroundColor="transparent" onClick={scrollToTop} visible={scrollLogoAppear}>
                                    <Icon filename="Logo" width="56px" height="60px" />
                                </Button>
                            </S.LogoBox>
                    }
                    
                    <S.Ul>
                        <li>
                            <ButtonRouterLink
                                to="/contact" 
                                textColor="#ffff"
                                activeClassName="active"
                            >
                                <TextAnimation
                                    text="CONTACT US"
                                    hoverText="CONTACT US"
                                    hoverTextColor="#fff"
                                    containerWidth="152px"
                                    containerHeight="58px"
                                    background="#0F3E52"
                                    padding="0px 24px 14px 24px"
                                    borderRadius="30px"
                                    bottom="18px"
                                    left="24px"
                                />
                            </ButtonRouterLink>
                        </li>
                    </S.Ul>
                </nav>
            </S.Header>
    }
  </>
}

export default Header