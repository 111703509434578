import React from 'react'
import { useParams } from 'react-router-dom';

// import FlexContainer from '../../components/common/FlexContainer'
import TextAnimation from '../../components/common/TextAnimation';
import MainLayout from '../../components/layouts/MainLayout'
// import HeaderText from '../../components/common/HeaderText'
import Typography from '../../components/common/Typography'
import Section from '../../components/common/Section'
import Ellipse from '../../components/common/Ellipse'
import Button from '../../components/common/Button'
import DivBox from '../../components/common/DivBox'
import Input from '../../components/common/Input'
import Label from '../../components/common/Label'

import { categoriesData } from '../Home/Home';
import { careerCategoriesData } from '../Career/Career';

import * as S from './styled'

const Job = () => {

  let {jobId, page} = useParams();
  
  const currentPageJobsData = page === "career" ? careerCategoriesData : categoriesData
  const jobData = currentPageJobsData.filter(jobData => jobData.id === jobId)

  // console.log('singleJobData', jobData)

  return (
    <MainLayout padding="0px" extraHeight="405px">
        <Ellipse
          top={20}
          left={0}
          background="rgba(221, 123, 16, 0.30)"
          backgroundOnMobile="rgba(221, 123, 16, 0.50)"
          topOnMobile={420}
          heightOnMobile="200px"
          widthOnMobile="200px"
          // translateXStart={0}
          // translateXEnd={100}
        />
        
        <Ellipse
          top={30}
          left={50}
          width="500px"
          height="500px"
          borderRadius="500px"
          filterBlur="blur(125px)"
          background="rgba(15, 62, 82, 0.80)"
          translateXStart={100}
          translateXEnd={0}
          widthOnMobile="250px"
          heightOnMobile="250px"
          topOnMobile={440}
          leftOnMobile={5}
      />

      <S.FlexBox justify="space-around" padding="60px 195px" align="stretch">
        <S.SectionBox maxWidth="60%">
            <S.MainHeaderText shadow>
             { jobData[0].role || "Job Title"}
            </S.MainHeaderText>
            <DivBox>
                <S.TypographyText fontSize="28px" color="rgba(255, 255, 255, 0.80)">{jobData[0].roleTitle}</S.TypographyText>
                {jobData[0].rolePlace && <Typography fontSize="14px" fontWeight="300" color="rgba(255, 255, 255, 0.80)">{jobData[0].rolePlace}</Typography>}
            </DivBox>
            <DivBox margin="40px 0px 24px 0px">
              <Typography
                fontSize="20px"
                fontWeight={700}
                color="rgba(255, 255, 255, 0.50)"
                textTransform="uppercase"
                margin="0px 0px 8px 0px"
              >
                The Role
              </Typography>
              <Typography
               fontSize="16px"
               fontWeight={300}
               color="rgba(255, 255, 255, 0.80)"
              >
                This is a fixed-term contract for 11 months, and you are expected to be in the office 3 to 4 days per week.
              </Typography>
            </DivBox>

            <DivBox>
              <Typography
                fontSize="20px"
                fontWeight={700}
                color="rgba(255, 255, 255, 0.50)"
                textTransform="uppercase"
                margin="0px 0px 8px 0px"
              >
                Who we are
              </Typography>

              <Typography
               fontSize="16px"
               fontWeight={300}
               color="rgba(255, 255, 255, 0.80)"
              >
                The Administrative Community is composed of global Business Partners who support Airbnb’s leaders and their teams around the world. Our Business Partners bolster Airbnb’s mission to create a world where anyone can belong by:
                Exercising sound judgment and strategy to coordinate core meetings
                Utilising diverse communications methods to execute projects and tasks critical to the business
                Developing, iterating and driving team operations
                Curating meaningful moments through team events and gatherings
                Influencing team culture through leadership and collaboration
                Our Business Partners are expected to be self-motivated, incredibly organized, and resourceful, with excellent communication skills, acute attention to detail and follow through, and the ability to thrive in a fast-paced and ever changing environment. It is important that our Business Partners have the ability to interact and manage up with staff at all levels while remaining flexible, proactive and efficient, with a high level of professionalism and confidentiality. 
                The Administrative Community is a critical partner that is an integral part of the operational success at Airbnb.
              </Typography>
            </DivBox>

            <DivBox>
              <Typography
                fontSize="20px"
                fontWeight={700}
                color="rgba(255, 255, 255, 0.50)"
                textTransform="uppercase"
                margin="24px 0px 8px 0px"
              >
                The difference you will make
              </Typography>

              <Typography
                fontSize="16px"
                fontWeight={300}
                color="rgba(255, 255, 255, 0.80)"
              >
              Airbnb is seeking an Executive Business Partner to support the Managing Director, EMEA & APAC. This is a full time position based in Paris, France. The Executive Business Partner is a strategic leader and requires an ability to interact with staff at all levels in a fast paced environment, sometimes under pressure, while remaining flexible, proactive, resourceful, efficient and demonstrating a high level of professionalism and confidentiality.      
              With so much going on in so many places, we need an experienced Executive Business Partner to join the team.
              </Typography>
            </DivBox>

            <DivBox>
              <Typography
                fontSize="20px"
                fontWeight={700}
                color="rgba(255, 255, 255, 0.50)"
                textTransform="uppercase"
                margin="24px 0px 8px 0px"
              >
                A typical day
              </Typography>

              <Typography
                fontSize="16px"
                fontWeight={300}
                color="rgba(255, 255, 255, 0.80)"
              >
                Manage all day to day administrative responsibilities for the Managing Director, EMEA & APAC. This includes advanced calendar management, travel and expense management, note-taking, meeting preparation and follow up, report and resource/database maintenance and coordinating office access. Act as a thought partner to the leader to strategically align their calendar with their priorities
                Plan and execute team events, offsites, activities, and recognition
                Assist and/or own content creation including drafting emails and creating presentations
                Manage purchase orders and partner with the leader and finance to track and analyse budget and expenses
                Provide support for project-related work. This includes preparing reports for quarterly business reviews and working with the leader to create onboarding plans for key team members. Additionally, process improvement, participating in strategic organisational activities, managing end-to-end external executive events, managing senior leadership agendas, and partnering with key external stakeholders (e.g. investors, M&A, government officials, strategic vendors)
                Maintain strong partnerships with internal stakeholders such as  internal/external comms, talent partners, D&B, FP&A, legal and recruiting for org effectiveness
                Manage and maintain the security of the leader’s email inbox, in compliance with our code of ethics and data privacy, security and protection policies as requested by the Leader
                Represent the organization to internal and external stakeholders
                Act as a mentor for other admins (where applicable). This includes meeting with a mentee on a regular basis and providing guidance and sharing resources to support their growth and development
                Build effective relationships within Airbnb to serve as a catalyst around scheduling and or spontaneous inquiries/requests
                Complete other duties and special projects as assigned
              </Typography>
            </DivBox>

            <DivBox>
              <Typography
                fontSize="20px"
                fontWeight={700}
                color="rgba(255, 255, 255, 0.50)"
                textTransform="uppercase"
                margin="24px 0px 8px 0px"
              >
                your expertise
              </Typography>

              <Typography
                fontSize="16px"
                fontWeight={300}
                color="rgba(255, 255, 255, 0.80)"
              >
                5+ years of relevant experience supporting senior level executives in an administrative capacity
                Previous experience in high growth environment strongly preferred
                Strong work ethic, proactive, exhibits positivity, professionalism, confidentiality and good judgment
                Outstanding verbal and written communication 
                Ability to deal with conflict as well as give and receive feedback diplomatically 
                Ability to navigate and thrive in ambiguity 
                Incredibly organised and resourceful; able to juggle and multi-task, with an acute attention to detail and follow through
                Advanced presentation skills (Google Docs, Keynote, Excel) and grasp of current social networking systems
                Passion for trying, learning, improving, and sharing best practices
                Ability to remain calm and poised under pressure
                May be required to travel up to 25%
              </Typography>
            </DivBox>
        </S.SectionBox>

        <Section>
          <S.ApplyNowText>
            Apply Now
          </S.ApplyNowText>

          <S.ApplyForm onSubmit={""}>
            <Label>
              First name
              <Input
                type="text"
                name="name"
                // value={}
                // onChange={}
                required
              />
            </Label>

            <Label>
              Last name
              <Input
                type="text"
                name="lastName"
                // value={}
                // onChange={}
                required
              />
            </Label>

            <Label>
              Email
              <Input
                type="email"
                name="email"
                // value={}
                // onChange={}
                padding="0px 50px"
                required
              />
            </Label>

            <Label>
              LinkedIn Link
              <Input
                type="text"
                name="linkedinLink"
                // value={}
                // onChange={}
                padding="0px 50px"
                required
              />
            </Label>

            <S.UploadInputBox>
              <S.UploadLabel>
                Upload resume
                <S.UploadInput
                  type="file"
                  name="uploadResume"
                  // value={}
                  // onChange={}
                  padding="0px 50px"
                  fileName="Upload"
                  withEndIcon
                  InputEndIconLeftPosition={"240px"}
                  InputEndIconTopPosition={'-20px'}
                  required
                />
              </S.UploadLabel>
            </S.UploadInputBox>


            <S.ApplyButtonBox>
              {/* <Button type="submit" padding="14px 48px" margin="40px 0px">
                APPLY
              </Button> */}
              <Button type="submit" padding="0px" margin="40px 0px">
                <TextAnimation
                    text="APPLY"
                    hoverText="APPLY"
                    containerWidth="147px"
                    containerHeight="48px"
                    hoverTextColor="#fff"
                    bottom="17px"
                    left="53px"
                />
              </Button>
            </S.ApplyButtonBox>
          </S.ApplyForm>
        </Section>
      </S.FlexBox>
    </MainLayout>
  )
}

export default Job