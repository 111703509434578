import styled from 'styled-components';

export const HeaderText = styled.h1`
    font-size: ${props => props.fontsize || "134px"};
    text-align: ${props => props.textAlign};
    margin:${props => props.margin};
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-image: ${props => props.shadow ? "linear-gradient(to bottom, rgba(255, 255, 255, 0.08) 60%, rgba(255, 255, 255, 0.00) 80%);" : "linear-gradient(180deg, rgba(255, 255, 255, 0.08) 71%, rgba(255, 255, 255, 0.00) 100%);"};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 767px) {
        font-size: ${props => props.mobileFontSize || "60px"};
        font-weight: ${props => props.mobileFontWeight || "700"};
        width: ${props => props.mobileWidth || "336px"};
        padding: ${props => props.mobilePadding};
        margin: ${props => props.mobileMargin};
        text-align: ${props => props.mobileTextAlign};
    }
`