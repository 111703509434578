import styled from 'styled-components';

export const TextArea = styled.textarea`
    height: ${props => props.height};
    width: ${props => props.width || "300px"};
    border: ${props => props.border || "none"};
    border-bottom: ${props => props.borderBottom || "2px solid rgba(255, 255, 255, 0.10)"};
    background: ${props => props.background || "transparent"};
    outline: none;
    padding: 10px 0px;
`