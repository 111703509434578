import React from 'react'

import Typography from '../../Typography'

import * as S from './styled'

const Card = ({cardId, cardNumber, cardText, margin}) => {
  return (
    <S.CardContainer key={cardId} margin={margin}>
        <S.CardNumber>
          {cardNumber || "01"}
        </S.CardNumber>
        <Typography
            fontSize='20px'
            fontWeight='500'
            color='rgba(255, 255, 255, 0.80)'
            textAlign="start"
        >
            {cardText || "There Should Be Card Text"}
        </Typography>
    </S.CardContainer>
  )
}

export default Card