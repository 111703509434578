import styled from 'styled-components';
import Icon from '../Icon';

export const InputContainer = styled.div`
    position: relative;
`
export const InputStartIcon = styled(Icon)`
    position: absolute;
    left: 0;
    right: ${props => props.InputStartIconRightPosition};
    left: ${props => props.InputStartIconLeftPosition || "0"};
`
export const InputEndIcon = styled(Icon)`
    position: absolute;
    right: ${props => props.InputEndIconRightPosition || "0"};
    left: ${props => props.InputEndIconLeftPosition || '140px'};
    top: ${props => props.InputEndIconTopPosition || '0'};
`
export const Input = styled.input`
  height: ${props => props.height};
  width: ${props => props.width || "300px"};
  margin: ${props => props.margin || "12px 0px"};
  padding: ${props => props.padding};
  border: ${props => props.border || "none"};
  border-bottom: ${props => props.borderBottom || "2px solid rgba(255, 255, 255, 0.10)"};
  background: ${props => props.background || "transparent"};
  outline: none;
  background-color: ${props => props.type === "file" && "red"};

  ::placeholder {
    color: ${props => props.placeholderColor}
  }
`;


export const UploadInput = styled.input`
  
`