import React from 'react'

import * as S from './styled'

const HeaderText = ({
  fontSize,
  border,
  shadow,
  textAlign,
  mobileFontSize,
  children,
  ...rest
}) => {
  return (
    <S.HeaderText
      fontsize={fontSize}
      border={border}
      shadow={shadow}
      textAlign={textAlign}
      mobileFontSize={mobileFontSize}
      {...rest}
    >
      {children || "Header Text"}
    </S.HeaderText>
  )
}

export default HeaderText