import InlineSVG from 'react-inlinesvg';

import styled from 'styled-components';


export const IconSvg = styled(InlineSVG)`
  fill: #DD7B10;
  position: ${props => props.position};
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  width: ${props => props.width || '100%'};
  z-index: ${props => props.zIndex};
  margin: ${props => props.margin};

  @media (max-width: 767px) {
    position: ${props => props.mobilePosition};
    top: ${props => props.mobileTop};
    bottom: ${props => props.mobileBottom};
    left: ${props => props.mobileLeft};
    right: ${props => props.mobileRight};
    margin: ${props => props.mobileMargin};
    height: ${props => props.mobileHeight};
    width: ${props => props.mobileWidth};
  }
`;