import React from 'react'

import PositionContainer from '../../components/common/PositionContainer'
import FlexContainer from '../../components/common/FlexContainer'
import HeaderText from '../../components/common/HeaderText'
import Typography from '../../components/common/Typography'
import Category from '../../components/common/Category'
import Ellipse from '../../components/common/Ellipse'
import Section from '../../components/common/Section'
import Cards from '../../components/common/Cards'
import Icon from '../../components/common/Icon'

import * as S from "./styled"

export const careerCategoriesData = [
  {
    role: "Administrative",
    roleTitle: "Executive Administrative Business Partner (Fixed-term contract)",
    rolePlace: "Dublin, Ireland orLondon, United Kingdom",
    jobLink: "link",
    id: "1",
  },
  {
    role: "Business Development",
    roleTitle: "Manager, Community Powered Growth",
    rolePlace: "United States",
    jobLink: "link",
    id: "2",
  },
  {
    role: "Business Operations",
    roleTitle: "Programs and Business Operations Lead",
    rolePlace: "United States",
    jobLink: "link",
    id: "3",
  },
  {
    role: "Community Support",
    roleTitle: "Fraud Service Design & Innovation Manager",
    rolePlace: "United States",
    jobLink: "link",
    id: "4",
  },
  {
    role: "Intern",
    roleTitle: "Data Analytics Intern, Global Risk and Internal Audit (MBA/MS)",
    rolePlace: "United States",
    jobLink: "link",
    id: "5",
  },
  {
    role: "Employee Experience",
    roleTitle: "Talent Enablement Advisor (HRBP)",
    rolePlace: "Paris, France",
    jobLink: "link",
    id: "6",
  },
  {
    role: "Employee Experience",
    roleTitle: "Talent Enablement Advisor (HRBP)",
    rolePlace: "Beijing, China",
    jobLink: "link",
    id: "7",
  },
  {
    role: "Finance",
    roleTitle: "Associate, Tax",
    rolePlace: "Gurugram, India",
    jobLink: "link",
    id: "8",
  },
  {
    role: "Information Technology",
    roleTitle: "Senior Systems engineer, Content Management",
    rolePlace: "United States",
    jobLink: "link",
    id: "9",
  },
  {
    role: "Marketing",
    roleTitle: "Senior Marketing Lead(Head of Marketing)",
    // rolePlace: "Japan",
    jobLink: "link",
    id: "10",
  },
]

const cardsData = [
  {
    cardId: 1,
    cardNumber: "01",
    cardText: "Find role which is your Rome Empire",
  },
  {
    cardId: 2,
    cardNumber: "02",
    cardText: "Check detail information about job",
  },
  {
    cardId: 3,
    cardNumber: "03",
    cardText: "Send us information about you with resume",
  },
  {
    cardId: 4,
    cardNumber: "04",
    cardText: "Wait for our reply. Good luck",
  },
]

const Career = () => {
  return (
    <>
        <Ellipse
          top={10}
          topOnMobile={10}
          left={0}
          background="rgba(221, 123, 16, 0.10)"
          backgroundOnMobile="rgba(221, 123, 16, 0.40)"
          zIndex="2"
          width="550px"
          widthOnMobile="200px"
          height="550px"
          heightOnMobile="200px"
          // translateXStart={0}
          // translateXEnd={100}
        />
        
        <Ellipse
          top={10}
          topOnMobile={30}
          left={50}
          leftOnMobile={5}
          width="660px"
          widthOnMobile="250px"
          height="660px"
          heightOnMobile="250px"
          borderRadius="500px"
          filterBlur="blur(125px)"
          background="rgba(15, 62, 82, 0.40)"
          backgroundOnMobile="rgba(15, 62, 82, 0.90)"
          translateXStart={100}
          translateXEnd={0}
      />

      <Ellipse
        top={50}
        topOnMobile={80}
        left={0}
        leftOnMobile={0}
        widthOnMobile="200px"
        heightOnMobile="200px"
        background="rgba(221, 123, 16, 0.25)"
        backgroundOnMobile="rgba(221, 123, 16, 0.5)"
        // translateXStart={0}
        // translateXEnd={100}
      />
        
      <Ellipse
        top={50}
        topOnMobile={100}
        left={50}
        leftOnMobile={5}
        width="500px"
        widthOnMobile="250px"
        height="500px"
        heightOnMobile="250px"
        borderRadius="500px"
        filterBlur="blur(125px)"
        background="rgba(15, 62, 82, 0.50)"
        backgroundOnMobile="rgba(15, 62, 82, 0.80)"
        translateXStart={100}
        translateXEnd={0}
      />

      <Section height="35rem" mobileHeight="30rem" margin="80px 0px" mobileMargin="20px 0px">
        <S.Career>
          <PositionContainer
            left="250px"
            top="-20px"
            zIndex="1"
            background="#0A151A"
            padding="100px 120px"
            mobileLeft={0}
            mobileTop="0px"
            mobileWidth="340px"
            mobileHeight="150px"
            mobilePadding="20px 20px 20px 24px"
            mobileZindex="10"
          >
            <HeaderText color="rgba(255, 255, 255, 0.10)">Careers</HeaderText>
            <Typography
              fontSize="32px"
              fontWeight="500"
              textAlign="right"
              mobileTextAlign="left"
            >
              Live and work from anywhere
            </Typography>
          </PositionContainer>
          <PositionContainer
            background="#0A151A"
            padding="20px 0px 20px 40px"
            left="300px"
            top="370px"
            zIndex="2"
            mobileTop="260px"
            mobileLeft="10px"
            mobileWidth="286px"
            mobileHeight="120px"
          >
            <FlexContainer>
              <S.NumberContainer>
                <S.NumberTexts mobileFontSize="60px">23</S.NumberTexts>
                <Typography
                  fontSize="32px"
                  fontWeight="300"
                  color="rgba(255, 255, 255, 0.80)"
                  mobileFontSize="24px"
                  mobileFontWeight="300"
                  >
                  Roles
                </Typography>
              </S.NumberContainer>

              <S.NumberContainer>
              <S.NumberTexts mobileFontSize="60px">11</S.NumberTexts>
                <Typography
                  fontSize="32px"
                  fontWeight="300"
                  color="rgba(255, 255, 255, 0.80)"
                  mobileFontSize="24px"
                  mobileFontWeight="300"
                  >
                  Location
                </Typography>
              </S.NumberContainer>
            </FlexContainer>
          </PositionContainer>

          <Icon
            filename="office"
            width="50%"
            position="absolute"
            left="630px"
            top="50px"
            zIndex="0"
            mobileTop="50px"
            mobileLeft="80px"
            mobileHeight="240px"
            mobileWidth="443px"
          />
        </S.Career>
      </Section>

      <Section
        display="flex"
        flexDirection="column"
        align="center"
        mobilePadding="0px 16px"
      >
        <Typography
          padding="20px 0px"
          fontWeight="500"
          fontSize="32px"
          textTransform="uppercase"
          color="rgba(255, 255, 255, 0.80)"
          mobileFontSize="18px"
          mobileLineHeight="36px"
          mobileFontWeight="500"
        >
          Create a world where anyone can belong anywhere
        </Typography>
        <Typography
          padding="0px 0px 80px 0px"
          fontSize="18px"
          fontWeight="300"
          width="50%"
          color="rgba(255, 255, 255, 0.80)"
          mobileFontSize="14px"
          mobileFontWeight="300"
          mobileLineHeight="24px"
          mobilePadding="0px 0px 40px 0px"
        >
          It’s an audacious, incredibly rewarding mission that our increasingly diverse team is dedicated to achieving. There’s life at work and life outside of work. We want everyone to be healthy, and have the financial resources and support they need.
        </Typography>
      </Section>

      <Section margin="0px 0px 36px 0px">
        <Cards
          cardsData={cardsData}
          mobileFlexDirection="column"
          mobilePadding="0px 16px"
        />
      </Section>

      {/* <Section
        margin="200px 0px 0px 0px"
        textAlign="center"
        mobileMargin="80px 0px 0px 0px"
        mobilePadding="0px 16px"
      >
        <HeaderText mobileFontSize="51px">Find Roles</HeaderText>
      </Section> */}

      {/* <FlexContainer mobilePadding="0px 16px" direction="column">
        <Category categoriesData={careerCategoriesData} />
      </FlexContainer> */}
    </>
  )
}

export default Career