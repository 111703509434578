import styled from 'styled-components';

export const Typography = styled.p`
    font-size: ${props => props.fontsize || "20px"};
    font-weight: ${props => props.fontWeight || 500};
    line-height: ${props => props.lineheight};
    color: ${props => props.color || "rgba(255, 255, 255, 0.90)"};
    text-transform: ${props => props.texttransform};
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    width: ${props => props.width};
    max-width: ${props => props.maxwidth};
    min-width: ${props => props.minwidth};
    transform: rotate(${props => props.rotate}deg);
    text-align: ${props => props.textalign};
    position: ${props => props.position};
    top: ${props => props.top};
    bottom: ${props => props.bottom};
    left: ${props => props.left};
    right: ${props => props.right};
    background: ${props => props.background};
    text-decoration-line: ${props => props.textDecoration};
    white-space: ${props => props.wrapText};


    @media (max-width: 767px) {
        font-size: ${props => props.mobileFontSize || "16px"};
        font-weight: ${props => props.mobileFontWeight || "700"};
        width: ${props => props.mobileWidth || "100%"};
        height: ${props => props.mobileHeight};
        text-align: ${props => props.mobileTextAlign};
        margin: ${props => props.mobileMargin};
        line-height: ${props => props.mobileLineHeight};
        padding: ${props => props.mobilePadding};
    }
`