import styled from 'styled-components'

export const Gif = styled.img`
   width: ${props => props.width};
   height: ${props => props.height};
   z-index: ${props => props.zindex};
   border-radius: ${props => props.borderRadius};
   position: ${props => props.position};
   margin: ${props => props.margin};
   padding: ${props => props.padding};
   transition-duration: 1s;
`