import styled, { keyframes } from 'styled-components';

import { FlexContainer } from '../FlexContainer/styled';

const slideUp = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
    display: block;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
    display: none;
  }
`;

const fadeIn = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: relative;
  width: ${props => props.containerwidth || "90px"};
  height: ${props => props.containerHeight || "40px"};
  background-color: ${props => props.background};
  padding: ${props => props.padding};
  border-radius: ${props => props.borderRadius};
  overflow: hidden;
`;

export const Text = styled.p`
  position: absolute;
  color: ${props => props.color || "#ffff"} ;
  bottom: ${props => props.bottom || "0"};
  left: ${props => props.left || "0"};
  font-size: ${props => props.fontsize};
  font-weight: ${props => props.fontweight};
  text-align: center;
  transition: transform 0.3s, opacity 0.3s;

  ${Container}:hover & {
    animation: ${slideUp} 0.5s forwards;
  }

  ${FlexContainer}:hover & {
    animation: ${slideUp} 0.5s forwards;
    cursor: pointer;
  }
`;


export const HoverText = styled.p`
  color: ${props => props.color || "#DD7B10"} ;
  position: absolute;
  bottom: ${props => props.bottom || "0"};
  font-size: ${props => props.fontsize};
  font-weight: ${props => props.fontweight};
  left: ${props => props.left || "0"};
  text-align: center;
  opacity: 0;
  transition: bottom 0.3s, opacity 0.3s;

  ${Container}:hover & {
    animation: ${fadeIn} 0.5s forwards;
  }

  ${FlexContainer}:hover & {
    animation: ${fadeIn} 0.5s forwards;
    cursor: pointer;
  }
`;