import React from 'react'

import * as S from './styled'

const Button = ({
    backgroundColor,
    textColor,
    radius,
    padding,
    margin,
    height,
    width,
    to,
    children,
    ...rest}) => {
  return (
    <S.Button
        background={backgroundColor}
        color={textColor}
        radius={radius}
        padding={padding}
        margin={margin}
        height={height}
        width={width}
        to={to}
        {...rest}
    >
       {children}
    </S.Button>
  )
}

export default Button