import React from 'react'

import * as S from './styled'

const Ellipse = ({
    width,
    height,
    borderRadius,
    background,
    filter,
    zIndex,
    ...rest
}) => {
  return (
        <S.Ellipse
            width={width}
            height={height}
            radius={borderRadius}
            background={background}
            zIndex={zIndex}
            {...rest}
        >
        </S.Ellipse>
        // <>
        //     <S.Ball1 style={{ top: '50%', left: '0%' }} />
        //     <S.Ball2 style={{ top: '50%', left: '100%' }} />
        // </>
  )
}

export default Ellipse