import styled from "styled-components";

export const DivBox = styled.div`
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    text-align: ${props => props.textAlign};
    width: ${props => props.width};

    @media (max-width: 767px) {
        margin: ${props => props.mobileMargin};
        padding: ${props => props.mobilePadding};
        text-align: ${props => props.mobileTextAlign};
        width: ${props => props.mobileWidth};
        overflow-x: ${props => props.mobileOverflowX};
    }
`