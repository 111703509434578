import React from 'react'

import * as S from './styled'

const MainLayout = ({extraHeight, padding, width, children, ...rest}) => {
  return (
    <S.MainLayout
      extraHeight={extraHeight}
      padding={padding}
      width={width}
      {...rest}
    >
        {children}
    </S.MainLayout>
  )
}

export default MainLayout