import React from 'react'

import * as S from './styled'

const Typography = ({
    id,
    fontSize,
    fontWeight,
    lineHeight,
    textTransform,
    textDecoration,
    maxWidth,
    textAlign,
    children,
    ...rest
}) => {
  return (
    <S.Typography
        key={id}
        fontsize={fontSize}
        fontWeight={fontWeight}
        lineheight={lineHeight}
        texttransform={textTransform}
        textDecoration={textDecoration}
        maxwidth={maxWidth}
        textalign={textAlign}
        {...rest}
    >
        {children}
    </S.Typography>
  )
}

export default Typography