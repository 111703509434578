import React from 'react'

import { useMediaQuery } from 'react-responsive';

import PositionContainer from '../../components/common/PositionContainer'
import Delivery from '../../assets/gifs/Delivery.jpg'
import FlexContainer from '../../components/common/FlexContainer'
// import TextAnimation from '../../components/common/TextAnimation';
import HeaderText from '../../components/common/HeaderText'
import Typography from '../../components/common/Typography'
import Ellipse from '../../components/common/Ellipse'
import DivBox from '../../components/common/DivBox'
import Icon from '../../components/common/Icon'
import Gif from '../../components/common/Gif'

import * as S from './styled'

const randtCorpTextArray = ["Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp","Randt Corp", "Randt Corp",]

const AboutUs = () => {

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {
        isMobile ? 
          <>

            <Ellipse
              topOnMobile={40}
              left={0}
              widthOnMobile="250px"
              heightOnMobile="250px"
              borderRadius="500px"
              filterBlur="blur(125px)"
              background="rgba(15, 62, 82, 0.80)"
              zIndex={100}
            />

            <Ellipse
              top={"-100px"}
              left={50}
              widthOnMobile="200px"
              heightOnMobile="200px"
              background="rgba(221, 123, 16, 0.80)"
              translateXStart={100}
              translateXEnd={0}
            />
            <FlexContainer justify="center" direction="column" padding="0px 16px">
              <HeaderText
                fontSize="60px"
                textAlign="center"
                margin="24px 0px 32px 0px"
              >
                About Us
              </HeaderText>
              <Typography
                fontSize="16px"
                fontWeight={500}
                lineHeight="28px"
                color="rgba(255, 255, 255, 0.80)"
                textAlign="start"
              >
                {/* Our mission is to create a haven for brands and give them the opportunity to expand beyond their reach. */}
                Welcome to RANDT Corp, your ultimate destination for all things shopping. Founded with a passion for innovation and a dedication to customer satisfaction, we've quickly emerged as a leading player in the e-commerce industry. At RANDT Corp, we're more than just an online marketplace – we're a community of like-minded individuals who share a common love for quality products and seamless shopping experiences.

              </Typography>
            </FlexContainer>
            <Icon filename="aboutUsBanner" width="100%" height="100%" />
            <FlexContainer justify="center" direction="column" padding="100px 16px 100px 16px">
              <DivBox mobileMargin="">
                <HeaderText 
                  mobileFontSize="54px" 
                  mobileWidth="100%"
                  textAlign="start"
                >
                  {/* How it all */}
                  Our Mission
                </HeaderText>
                {/* <Icon filename="started" width="100%" /> */}
              </DivBox>
              <Typography mobileFontSize="14px" mobileFontWeight="300">
                {/* What began as a simple job as a stock boy in a retail store, blossomed into a passion for building relationships and a deep understanding of consumer brands. Ikey Sabbagh has spent decades evolving and pushing the world of commerce forward. With experience in both sales and manufacturing, Ikey has been able to build a successful distribution model. */}
                Our mission at RANDT Corp is simple: to revolutionize the way you shop online. We believe that shopping should be easy, enjoyable, and rewarding, which is why we've made it our mission to provide you with a curated selection of top-notch products, unparalleled customer service, and a hassle-free shopping experience from start to finish.
              </Typography>
              {/* <Typography mobileFontSize="14px" mobileFontWeight="300" mobileMargin="24px 0px 0px 0px">
                This model uses technology to solve for areas of commerce that lacked protection, transparency, convenience , and innovation. Ikey created the CLS Brands application with the goal of creating an end-to end solution for the distribution market.  
              </Typography> */}
            </FlexContainer>
              <FlexContainer
                mobileBorderTop="1px solid rgba(255, 255, 255, 0.70)"
                mobileOverflowY="hidden"
              >
                <marquee
                  direction="down"
                  behavior="scroll"
                  scrollamount="10"
                  height="750px"
                  width="160px"
                >
                  <FlexContainer direction="column" justify="center"  mobileGap="300px 0px">
                    {randtCorpTextArray.map(((text, index) => (
                      <Typography
                        id={index}
                        color="#DD7B10"
                        textTransform="uppercase"
                        mobileFontSize="50px"
                        fontWeight="700"
                        rotate={-90}
                        mobileWidth="500px"
                      >
                        {text}
                      </Typography>
                    )
                    ))}
                  </FlexContainer>
                </marquee>

                <PositionContainer position="relative">
                  <Gif src={Delivery} altName="postDelivery" zindex="1" width="100%" height="747px" />
                  <PositionContainer padding="0px 16px" top={0} width="100%" height="100%" background="linear-gradient(129deg, rgba(10, 21, 26, 0.80) 27.63%, rgba(10, 21, 26, 0.70) 86.25%)">
                  <DivBox>
                      <FlexContainer justify="center">
                        <Icon filename="AboutUsRandtGroup" margin="40px 0px 40px 0px" width="256px" height="97px" />
                      </FlexContainer>
                      <Typography
                        mobileFontSize="14px"
                        mobileFontWeight={400}
                        color="rgba(255, 255, 255, 0.70)"
                        lineHeight="24px"
                      >
                        {/* We at Randt Corp Brands pride ourselves as a trusted fulfillment house that cares for our partners. */}
                        At RANDT Corp, we're committed to going above and beyond for our customers.
                      </Typography>

                      <Typography
                        mobileFontSize="14px"
                        mobileFontWeight={400}
                        color="rgba(255, 255, 255, 0.70)"
                        padding="32px 0px"
                        lineHeight="24px"
                      >
                        {/* By allying with Salesforce in 2020, we created an application to simplify the process of selling and creating brand exposure. With over 200 online retail partners and global fulfillment partners, CLS Brands has built a monumental solution for all brands and retailers.Our application allows brands the ability to control their products, orders, provide customer service, marketing and most importantly pricing globally. It’s simple and easy. */}
                        From our handpicked selection of products to our user-friendly interface and lightning-fast shipping, every aspect of our platform is designed with you in mind. We take pride in offering a diverse range of products that cater to every taste, budget, and lifestyle, ensuring that there's something for everyone at RANDT Corp.
                      </Typography>

                      <Typography
                        mobileFontSize="20px"
                        mobileFontWeight={400}
                        color="#DD7B10"
                        lineHeight="24px"
                      >
                        Contact us today to see how we can add value to your business.
                      </Typography>
                  </DivBox>
                  </PositionContainer>
                </PositionContainer>

              </FlexContainer>
          </>
        :
          <>
            <Ellipse
              top={20}
              left={0}
              width="500px"
              height="500px"
              borderRadius="500px"
              filterBlur="blur(125px)"
              background="rgba(15, 62, 82, 0.40)"
              zIndex={100}
              // translateXStart={100}
              // translateXEnd={0}
            />

            <Ellipse
              top={"-100px"}
              left={50}
              background="rgba(221, 123, 16, 0.20)"
              translateXStart={100}
              translateXEnd={0}
            />

          <FlexContainer justify="flex-start" align="stretch" margin="100px 0px 0px 0px">      
            <S.AboutUs>
                <FlexContainer padding="0px 0px 0px 195px">
                  <FlexContainer direction="column" align="start" width="755px">
                    <HeaderText>About Us</HeaderText>
                    <Typography
                      fontSize="24px"
                      fontWeight="500"
                      color="rgba(255, 255, 255, 0.80)"
                    >
                      {/* Our mission is to create a haven for brands and give them the opportunity to expand beyond their reach. */}
                      Welcome to RANDT Corp, your ultimate destination for all things shopping. Founded with a passion for innovation and a dedication to customer satisfaction, we've quickly emerged as a leading player in the e-commerce industry. At RANDT Corp, we're more than just an online marketplace – we're a community of like-minded individuals who share a common love for quality products and seamless shopping experiences.
                    </Typography>
                  </FlexContainer>

                  <PositionContainer position="relative">
                    <Icon filename="AboutUsImage" />
                    <Icon
                      filename="VectorImg"
                      position="absolute"
                      top="-40px"
                      left="-3px"
                    />
                  </PositionContainer>
                </FlexContainer>

                <FlexContainer justify="flex-start" padding="190px 0px 190px 195px">
                  <FlexContainer justify="center" align="start" direction="column" width="50%">
                    <HeaderText fontSize="105px">Our Mission</HeaderText>
                    {/* <Icon filename="started" width="80%" /> */}
                  </FlexContainer>
                  <FlexContainer justify="center" align="start" direction="column" width="40%">
                    <Typography fontSize="24px" fontWeight={300} color="rgba(255, 255, 255, 0.8)">
                      {/* What began as a simple job as a stock boy in a retail store, blossomed into a passion for building relationships and a deep understanding of consumer brands. Ikey Sabbagh has spent decades evolving and pushing the world of commerce forward. With experience in both sales and manufacturing, Ikey has been able to build a successful distribution model. */}
                      Our mission at RANDT Corp is simple: to revolutionize the way you shop online. We believe that shopping should be easy, enjoyable, and rewarding, which is why we've made it our mission to provide you with a curated selection of top-notch products, unparalleled customer service, and a hassle-free shopping experience from start to finish.
                    </Typography>
                    {/* <Typography fontSize="16px" fontWeight={300} color="rgba(255, 255, 255, 0.8)" margin="10px 0px">
                    This model uses technology to solve for areas of commerce that lacked protection, transparency, convenience , and innovation. Ikey created the CLS Brands application with the goal of creating an end-to end solution for the distribution market.  
                    </Typography> */}
                  </FlexContainer>
                </FlexContainer>

                <FlexContainer justify="flex-start" padding="0px 0px 190px 195px">
                  <FlexContainer justify="center" align="start" direction="column" width="40%">
                    <Typography fontSize="24px" fontWeight={300} color="rgba(255, 255, 255, 0.8)">
                      Behind every successful e-commerce platform is a dedicated team of professionals, and at RANDT Corp, we're no exception. Our team consists of industry experts, tech enthusiasts, and customer service specialists who are passionate about delivering excellence in everything we do. Together, we work tirelessly to bring you the best possible shopping experience, one click at a time.
                    </Typography>
                  </FlexContainer>
                  <FlexContainer justify="center" align="right" direction="column" width="50%">
                    <HeaderText fontSize="110px">Our Team</HeaderText>
                  </FlexContainer>
                </FlexContainer>
              
              <FlexContainer justify="flexStart" width="100%">
                <marquee
                  direction="down"
                  behavior="scroll"
                  scrollamount="10"
                  height="820px"
                  width="200px"
                >
                  <FlexContainer direction="column" justify="center"  gap="400px 0px">
                    {randtCorpTextArray.map(((text, index) => (
                      <Typography
                        id={index}
                        color="#DD7B10"
                        textTransform="uppercase"
                        fontSize="60px"
                        fontWeight="700"
                        rotate={-90}
                        width="100%"
                        wrapText="nowrap"
                      >
                        {text}
                      </Typography>
                    )
                    ))}
                  </FlexContainer>
                </marquee>
                
                <PositionContainer width="100%" position="relative" margin="40px 0px">  
                  <Gif height="820px" src={Delivery} altName="postDelivery" zindex="1" width="100%" />
                    <PositionContainer top="0" height="100%" width="100%" background="linear-gradient(129deg, rgba(10, 21, 26, 0.80) 27.63%, rgba(10, 21, 26, 0.70) 86.25%)">
                      <FlexContainer padding="300px 0px" justify="space-around">
                        <DivBox>
                          <Icon filename="AboutUsRandtGroup" />
                        </DivBox>
                        <FlexContainer direction="column" align="start" justify="start" width='755px'>
                          <Typography fontSize="16px" fontWeight="400" color="rgba(255, 255, 255, 0.70)">
                            {/* We at Randt Corp Brands pride ourselves as a trusted fulfillment house that cares for our partners. */}
                            At RANDT Corp, we're committed to going above and beyond for our customers.
                          </Typography>

                          <Typography fontWeight="400" fontSize="16px" color="rgba(255, 255, 255, 0.70)" padding="30px 0px">
                           {/* By allying with Salesforce in 2020, we created an application to simplify the process of selling and creating brand exposure. With over 200 online retail partners and global fulfillment partners, CLS Brands has built a monumental solution for all brands and retailers.Our application allows brands the ability to control their products, orders, provide customer service, marketing and most importantly pricing globally. It’s simple and easy. */}
                           From our handpicked selection of products to our user-friendly interface and lightning-fast shipping, every aspect of our platform is designed with you in mind. We take pride in offering a diverse range of products that cater to every taste, budget, and lifestyle, ensuring that there's something for everyone at RANDT Corp.
                          </Typography>

                          <Typography fontSize="24px" fontWeight="400" color="#DD7B10">
                            Contact us today to see how we can add value to your business.
                          </Typography>

                            {/* <TextAnimation
                              text="Contact us today to see how we can add value to your business."
                              hoverText="Contact us today to see how we can add value to your business."
                              containerWidth="100%"
                              containerHeight="60px"
                              color="#DD7B10"
                              hoverTextColor="#fff"
                              fontSize="24px"
                              fontWeight="400"
                            /> */}

                        </FlexContainer>
                      </FlexContainer>
                    </PositionContainer>
                </PositionContainer>
              </FlexContainer>
            </S.AboutUs>
          </FlexContainer>
          </>
      }
    </>
  )
}

export default AboutUs