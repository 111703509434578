import React, {useEffect, useState} from 'react'

import { useMediaQuery } from 'react-responsive';
import axios from 'axios';

import FlexContainer from '../../components/common/FlexContainer'
import TextAnimation from '../../components/common/TextAnimation';
import MainLayout from '../../components/layouts/MainLayout'
import HeaderText from '../../components/common/HeaderText'
import Typography from '../../components/common/Typography'
import TextArea from '../../components/common/TextArea'
import Ellipse from '../../components/common/Ellipse'
import Button from '../../components/common/Button'
import DivBox from '../../components/common/DivBox'
import Modal from '../../components/common/Modal'
import Input from '../../components/common/Input'
import Label from '../../components/common/Label'
import Icon from '../../components/common/Icon'

import * as S from './styled'

const ContactUs = () => {

  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleChangeContactForm = (e) => {
    const { name, value } = e.target;
    
    setContactFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmitConcatFormData = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://formspree.io/f/mdoqyrrv", contactFormData);
      console.log('Message sent successfully')
    } catch (error) {
      console.error('Error sending message:', error);
    }
    
    setContactFormData({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  return (
    
    <MainLayout padding="0px" extraHeight="405px">
    <S.DivContainer>
      <Ellipse
        top={20}
        topOnMobile={40}
        left={0}
        background="rgba(221, 123, 16, 0.30)"
        backgroundOnMobile="rgba(221, 123, 16, 0.50)"
        widthOnMobile="200px"
        heightOnMobile="200px"
        // translateXStart={0}
        // translateXEnd={100}
      />
        
      <Ellipse
        top={30}
        topOnMobile={70}
        left={50}
        width="500px"
        height="500px"
        widthOnMobile="250px"
        heightOnMobile="250px"
        borderRadius="500px"
        filterBlur="blur(125px)"
        background="rgba(15, 62, 82, 0.70)"
        translateXStart={100}
        translateXEnd={0}
      />
      <S.FlexBox justify="space-between">
        <FlexContainer direction="column">
          <HeaderText
            mobileFontSize="50px"
            mobilePadding="24px 0px 32px 0px"
            mobileTextAlign="center"
          >
            Contact Us
          </HeaderText>
          <Typography
            fontSize="20px"
            fontWeight="500"
            color="rgba(255, 255, 255, 0.80)"
            width="892px"
            mobileTextAlign="start"
          >
            {/* Thank you for your interest in Randt Corporation. Please let us know if you’d like to find out more */}
            Whether you're a seasoned shopper or new to the world of online shopping, we invite you to join us on this exciting journey. Discover the endless possibilities that await you at RANDT Corp, and experience the future of online shopping today. Thank you for choosing us as your trusted shopping destination.
          </Typography>
        </FlexContainer>
        {
          !isMobile &&
            <DivBox>
              <Icon filename="ContactUsImg" />
            </DivBox>
        }
      </S.FlexBox>

      {
        isMobile &&
          <FlexContainer justify="flex-end" margin="20px 0px">
            <Icon filename="ContactUsImg" width="359px" height="150px" textAlign="end"/>
          </FlexContainer>
      }

      <MainLayout>
        <Modal display='flex' justify='space-around' width="1142px" margin="120px auto 100px auto">
          <S.ContactInfoBox>
            <Typography
              textTransform="uppercase"
              fontSize="32px"
              mobileFontSize="24px"
              color="rgba(255, 255, 255, 0.80)"
              fontWeight="700"
              padding="0px 0px 50px 0px"
              mobileMargin="80px 0px 0px 0px"
            >
              Contact Info
            </Typography>

            <FlexContainer
              justify="flex-start"
              padding="0px 0px 40px 0px"
              margin="0px 0px 40px 0px"
              borderBottom="2px solid rgba(255, 255, 255, 0.10)"
            >
              <DivBox>
                <Icon filename="Location" width="24px" margin="0px 20px 0px 0px" />
              </DivBox>

              <DivBox>
                <Typography
                  textTransform="uppercase"
                  fontSize="16px"
                  fontWeight="500"
                  color="rgba(255, 255, 255, 0.80)"
                  padding="0px 0px 8px 0px"
                >
                  CORPORATE ADDRESS
                </Typography>

                <Typography
                  fontSize="14px"
                  fontWeight="300"
                  color="rgba(255, 255, 255, 0.80)"
                >
                  {/* 625 Montrose ave South Plainfield NJ 07080 */}
                  1609 Voorhies avenue APT 3C
                </Typography>

                <Typography
                  fontSize="14px"
                  fontWeight="300"
                  color="rgba(255, 255, 255, 0.80)"
                >
                  Brooklyn, New York
                </Typography>
                
                <Typography
                  fontSize="14px"
                  fontWeight="300"
                  color="rgba(255, 255, 255, 0.80)"
                >
                  11235
                </Typography>
              </DivBox>
            </FlexContainer>

            <FlexContainer
              justify="flex-start"
              padding="0px 0px 40px 0px"
              margin="0px 0px 40px 0px"
              borderBottom="2px solid rgba(255, 255, 255, 0.10)"
            >
              <DivBox>
                <Icon filename="Phone" width="24px" margin="0px 20px 0px 0px" />
              </DivBox>

              <S.Box>
                <Typography
                  textTransform="uppercase"
                  fontSize="16px"
                  fontWeight="500"
                  color="rgba(255, 255, 255, 0.80)"
                  padding="0px 0px 8px 0px"
                >
                  PHONE NUMBER
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight="300"
                  color="rgba(255, 255, 255, 0.80)"
                >
                  {/* +1 (555) 556-954444 */}
                  +1 (929) 382-8394
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight="300"
                  color="rgba(255, 255, 255, 0.80)"
                >
                  +1 (347) 954-6448
                </Typography>
              </S.Box>
            </FlexContainer>

            <FlexContainer
              justify="flex-start"
              padding="0px 0px 40px 0px"
            >
              <DivBox>
                <Icon filename="Email" width="24px" margin="0px 20px 0px 0px" />
              </DivBox>

              <DivBox>
                <Typography
                  textTransform="uppercase"
                  fontSize="16px"
                  fontWeight="500"
                  color="rgba(255, 255, 255, 0.80)"
                  padding="0px 0px 8px 0px"
                >
                  EMAIL
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight="300"
                  color="rgba(255, 255, 255, 0.80)"
                >
                  info@randtcorp.com
                </Typography>
              </DivBox>
            </FlexContainer>
          </S.ContactInfoBox>

          <DivBox>
            <Typography
              textTransform="uppercase"
              fontSize="32px"
              mobileFontSize="24px"
              color="rgba(255, 255, 255, 0.80)"
              fontWeight="700"
              mobilePadding="0px"
              mobileMargin="0px"
            >
              Get In Touch
            </Typography>

            <S.SubmitForm onSubmit={handleSubmitConcatFormData}>
              <Label>
                Name
                <Input
                  type="text"
                  name="name"
                  value={contactFormData.name}
                  onChange={handleChangeContactForm}
                  required
                />
              </Label>

              <Label>
                Email
                <Input
                  type="email"
                  name="email"
                  value={contactFormData.email}
                  onChange={handleChangeContactForm}
                  required
                />
              </Label>
              <Label>
                Phone
                <Input
                  type="tel"
                  name="phone"
                  value={contactFormData.phone}
                  onChange={handleChangeContactForm}
                  // padding="0px 50px"
                  required
                />
              </Label>
              <Label>
                <p>
                  Type Your Message here
                </p>
                <TextArea
                  name="message"
                  value={contactFormData.message}
                  onChange={handleChangeContactForm}
                  required
                />
              </Label>

              <S.SubmitButtonBox>
                {/* <Button type="submit" padding="14px 48px">
                  SUBMIT
                </Button> */}
                
                <Button type="submit" padding="0px">
                <TextAnimation
                    text="SUBMIT"
                    hoverText="SUBMIT"
                    containerWidth="161px"
                    containerHeight="48px"
                    hoverTextColor="#fff"
                    bottom="17px"
                    left="55px"
                />
                </Button>
              </S.SubmitButtonBox>
            </S.SubmitForm>
          </DivBox>
        </Modal>
      </MainLayout>
    </S.DivContainer>

    </MainLayout>
  )
}

export default ContactUs