import React from 'react'

import { useMediaQuery } from 'react-responsive';

import MainLayout from '../../components/layouts/MainLayout'
import FlexContainer from '../../components/common/FlexContainer'
import HeaderText from '../../components/common/HeaderText'
import Typography from '../../components/common/Typography'
import Icon from '../../components/common/Icon'

import * as S from './styled'

const Brands = () => {

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isMobile ?
        <FlexContainer align="center" justify="center" direction="column">
          <HeaderText fontSize="60px">BRANDS</HeaderText>
          <Typography fontSize="16px" fontWeight={500} color="rgba(255, 255, 255, 0.80)" margin="32px 0px 60px 0px">
            Our mission is to create a haven for brands and give them the opportunity to expand beyond their reach.
          </Typography>
          <Icon filename="PartnersMobile" margin="0px 0px 80px 0px" />
        </FlexContainer>
      :
        <MainLayout padding="0px" extraHeight="405px">
          <FlexContainer direction="column" align="center" justify="center">
            <HeaderText fontSize="154px">Brands</HeaderText>
            <Typography
              fontSize="24px"
              width="50%"
              fontWeight="400"
              color="rgba(255, 255, 255, 0.80)"
            >
              Our mission is to create a haven for brands and give them the opportunity to expand beyond their reach.
            </Typography>
          </FlexContainer>
          <Icon filename="Partners" width="100%" />
        </MainLayout>
      }
    </>
  )
}

export default Brands