import React from 'react'

import * as S from "./styled"

const FlexContainer = ({
    id,
    justify,
    align,
    maxWidth,
    flexWrap,
    borderBottom,
    mobileFlexDirection,
    children,
    ...rest
  }) => {
  return (
    <S.FlexContainer
      key={id}
      justify={justify}
      align={align}
      maxWidth={maxWidth}
      flexWrap={flexWrap}
      borderBottom={borderBottom}
      mobileFlexDirection={mobileFlexDirection}
      {...rest}
    >
      {children}
    </S.FlexContainer>
  )
}

export default FlexContainer