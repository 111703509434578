import React from 'react'

import { useMediaQuery } from 'react-responsive';

import FlexContainer from '../FlexContainer/FlexContainer'
import ButtonRouterLink from '../ButtonRouterLink'
import TextAnimation from '../TextAnimation'
import Typography from '../Typography';
import DivBox from '../DivBox';
import Icon from '../Icon'

import * as S from './styled'

const Category = ({categoriesData}) => {

  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
        {isMobile ?
            <>
                {
                    categoriesData.map((categoryData, index) => (
                        <DivBox>
                            <ButtonRouterLink to={categoriesData[0].page === "home" ? `/home/job/${categoryData.id}` : `/career/job/${categoryData.id}`}>
                                <Typography
                                    color="#0F3E52"
                                    mobileFontSize="24px"
                                    mobileFontWeight={600}
                                    margin="0px 0px 12px 0px"
                                >
                                    {categoryData.role}
                                </Typography>
                            </ButtonRouterLink>

                            <Typography
                                mobileFontSize="16px"
                                mobileFontWeight={400}
                                margin={index === categoriesData.length - 1 && "0px 0px 80px 0px"}
                            >
                                {categoryData.roleTitle}
                            </Typography>
                            {categoryData.rolePlace && 
                                <S.RolePlace>{categoryData.rolePlace}</S.RolePlace>
                            }

                            {
                                index !== categoriesData.length - 1 &&
                                    <Icon filename="categoryLine" margin="24px 0px" />
                            }

                        </DivBox>
                    )
                    )
                }
            </>
        :
            <S.CategoriesContainer>
                {
                    categoriesData.map((categoryData, index) => <>
                        <FlexContainer id={categoryData.id} width="100%">
                            <S.CategoriesBox>
                                <TextAnimation
                                    containerWidth="500px"
                                    fontSize="40px"
                                    fontWeight="600"
                                    text={categoryData.role}
                                    hoverText={categoryData.role}
                                    color="#0F3E52"
                                    hoverTextColor="#0F3E52"
                                />
                            </S.CategoriesBox>
                            <S.CategoriesBox>
                                <S.RoleTitle>{categoryData.roleTitle}</S.RoleTitle>

                                {categoryData.rolePlace && 
                                    <S.RolePlace>{categoryData.rolePlace}</S.RolePlace>
                                }
                            </S.CategoriesBox>
                            <ButtonRouterLink to={categoriesData[0].page === "home" ? `/home/job/${categoryData.id}` : `/career/job/${categoryData.id}`}>        
                                <S.ArrowBox>
                                    <Icon filename="Arrow" />
                                </S.ArrowBox>
                            </ButtonRouterLink>
                        </FlexContainer>
                        {
                            index !== categoriesData.length - 1 &&
                            <S.LineBox>
                                <Icon filename="Line" />
                            </S.LineBox>
                        }
                    </>
                    )
                }
            </S.CategoriesContainer>
        }
    </>
  )
}

export default Category