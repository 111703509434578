import React from 'react'

import * as S from './styled'

const Section = ({textAlign, maxWidth, flexDirection, children, ...rest }) => {
  return (
    <S.Section
        textAlign={textAlign}
        maxWidth={maxWidth}
        flexDirection={flexDirection}
        {...rest}
    >
        {children}
    </S.Section>
  )
}

export default Section