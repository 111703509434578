import React from 'react'

import * as S from './styled'

const Label = ({ 
    fontSize,
    fontWeight,
    lineHeight,
    textTransform,
    maxWidth,
    children,
    ...rest
}) => {
  return (
    <S.Label
        fontsize={fontSize}
        fontweight={fontWeight}
        lineheight={lineHeight}
        texttransform={textTransform}
        maxwidth={maxWidth}
        {...rest}
    >
        {children}
    </S.Label>
  )
}

export default Label