import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  0% {
    transform: translate(${props => props.translateXStart}%) rotate(${props => props.startRotation}deg);
  }

  30% {
    transform: translate(-100%) rotate(180deg);
    /* transform: translateX(50%) rotate(180deg); */
  }
  50%{
    transform: translate(50%) rotate(200deg);
    /* transform: translateX(100%) rotate(200deg); */
  }
  100% {
    transform: translate(${props => props.translateXEnd}%) rotate(${props => props.startRotation}deg);
    /* transform: translateY(100%) rotate(360deg); */
  }
`;

export const Ellipse = styled.div`
   position: absolute;
   top: ${props => props.top || 20}%;
   left: ${props => props.left || 0}%;
   right: ${props => props.right}%;
   bottom: ${props => props.bottom}%;
   width: ${props => props.width || '400px'};
   height: ${props => props.height || '400px'};
   border-radius: ${props => props.radius || '400px'};
   background: ${props => props.background || 'rgba(221, 123, 16, 0.40)'};
   filter: ${props => props.filterBlur || "blur(100px)"};
   animation: ${rotate} ${props => props.duration || 20}s linear infinite;
   z-index: ${props => props.zIndex || "-10000000"};

    @media (max-width: 767px) {
      width: ${props => props.widthOnMobile || "200px"};
      height: ${props => props.heightOnMobile || "200px"};
      top: ${props => props.topOnMobile || 20}%;
      left: ${props => props.leftOnMobile || 0}%;
      right: ${props => props.rightOnMobile}%;
      bottom: ${props => props.bottomOnMobile}%;
      background: ${props => props.backgroundOnMobile};
    }
`


// /* const moveLeftToRight = keyframes`
// 0% {
//     transform: translate(-50%, -50%) rotate(0deg) translateX(0) translateY(-100%);
//   }
//   50% {
//     transform: translate(-50%, -50%) rotate(0deg) translateX(0) translateY(0);
//   }
//   100% {
//     transform: translate(-50%, -50%) rotate(0deg) translateX(-100%) translateY(0);
//   }

// `;

// const moveRightToLeft = keyframes`
// 0% {
//     transform: translate(-50%, -50%) rotate(0deg) translateX(0) translateY(100%);
//   }
//   50% {
//     transform: translate(-50%, -50%) rotate(0deg) translateX(0) translateY(0);
//   }
//   100% {
//     transform: translate(-50%, -50%) rotate(0deg) translateX(100%) translateY(0);
//   }
// `;


// export const Ball1 = styled.div`
//   position: absolute;
//   width: 500px;
//   height: 500px;
//   background-color: #3498db;
//   border-radius: 50%;
//   animation: ${moveRightToLeft} 5s linear infinite;
// `;

// export const Ball2 = styled.div`
//   position: absolute;
//   width: 400px;
//   height: 400px;
//   background-color: #e74c3c;
//   border-radius: 50%;
//   animation: ${moveLeftToRight} 5s linear infinite;
// `; */